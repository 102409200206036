import axios from './instance'

// 登录
export async function getDingAuth(query) {
    console.log('钉钉扫码登录', query)
    return axios.post(`/api/ding/login?${query}`)
}
// 新用户注册
export async function registerUser(data) {
    console.log('新用户注册', data)
    return axios.post('/api/user', data)
}
// 验证用户名是否存在
export async function existsLoginName(params) {
    console.log('验证用户名是否存在', params)
    return axios.get('/api/user/login-name/exists', { params })
}
// 网页账号登录
export async function webLogin(params) {
    return axios.post(`/api/login?${params}`)
}
// 登录图片验证码
export async function getLoginCode(params) {
    return axios.get('/api/login/code/image', {
        responseType: 'blob'
    })
}
// 网页钉钉登录
export async function webDDLogin(params) {
    console.log('网页钉钉登录', params)
    return axios.post(`/api/ding/login?${params}`)
}
import React from 'react'
import styled, { css } from 'styled-components'
import { vw } from 'common/utils'
import tipBg from 'common/img/tip-bg.png'

const TipWrapper = styled.div`
    position: absolute;
    right: 44px;
    top: -9px;
`

const TipInner = styled.div`
    position: relative;
`

const Tip = styled.div`
    height: 30px;
    line-height: 30px;
    background: linear-gradient(90deg, #c93b83, #5B5EC6);
    border-radius: 0 15px 15px 0;
    color: white;
    text-align: left;
    white-space: nowrap;
    padding-right: 18px;
    position: absolute;
    top: 0;
    left: 17px;
    z-index: 92;
`

const TipPrefix = styled.div`
    width: 17px;
    height: 34px;
    background: url(${tipBg});
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
`

const PlanItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${vw(100)};
`

const Plan = styled.div`
    width: 240px;
    min-height: 240px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.15);
    border-radius: 9px;
    text-align: center;
    cursor: pointer;
    padding: 28px 16px 16px 8px;
    position: relative;
    ${props => props.active && css`
        border-color: #DF615A;
    `}
`

const Title = styled.div`
    font-size: 24px;
    font-weight: normal;
    color: #333333;
    line-height: 1;
    margin-bottom: 22px;
`

const Point = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 1;
`

const AmountText = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: #DD5048;
    line-height: 1;
    margin: 42px 0 24px 0;
`

const Amount = styled.div`
    font-size: 36px;
    font-family: Arial Black;
    font-weight: 400;
    color: #DD5048;
    line-height: 1;
`

const Tips = ({ text }) => {
    return (
        <TipWrapper>
            <TipInner>
                <Tip>{text}</Tip>
                <TipPrefix />
            </TipInner>
        </TipWrapper>
    )
}

export default ({ data = {}, current, onSelected }) => {
    const { id, amount, point, discount } = data
    return (
        <PlanItem>
            <Plan
                key={id}
                onClick={onSelected.bind(this, data)}
                active={current.id === id}
            >
                {discount && <Tips text={discount} />}
                <Title>{point}场比赛</Title>
                <Point>购买{point}个云点，可进行{point}场比赛</Point>
                <AmountText>金额</AmountText>
                <Amount>￥{amount}</Amount>
            </Plan>
        </PlanItem>
    )
}
import axios from './instance'
import qs from 'qs'

// ​比赛列表
export async function getCompetitionList(params) {
    console.log('api 比赛列表', params)
    return axios.get('/api/competition/page', { params })
}
// 获取当前用户相关联的比赛 
export async function getCompetitionManageable() {
    return axios.get('/api/competition/list')
}
// 新增比赛
export async function addCompetition(data) {
    console.log('api 新增比赛', data)
    return axios.post('/api/competition', data)
}
// 比赛基础设置
export async function getCompetitionDetail(competitionId) {
    console.log('api 获取比赛基础设置', competitionId)
    return axios.get(`/api/competition/${competitionId}/base/setting`)
}
// 比赛打分设置
export async function getCompetitionScoreDetail(competitionId) {
    console.log('api 获取比赛打分设置', competitionId)
    return axios.get(`/api/competition/${competitionId}/score/setting`)
}
// 修改比赛名称
export async function updateMatchName(competitionId, data) {
    console.log('api 修改比赛名称', competitionId)
    return axios.patch(`/api/competition/${competitionId}/name`, data)
}
// 比赛状态
export async function getMatchStatus(competitionId) {
    console.log('api 比赛状态', competitionId)
    return axios.get(`/api/competition/${competitionId}/status`)
}
// 比赛基础信息修改
export async function updateCompetition(competitionId, data) {
    console.log('api 比赛基础信息修改', competitionId, data)
    return axios.patch(`/api/competition/${competitionId}/base/setting`, data)
}
// 比赛打分信息修改
export async function updateCompetitionScore(competitionId, data) {
    console.log('api 比赛打分信息修改', competitionId, data)
    return axios.patch(`/api/competition/${competitionId}/score/setting`, data)
}
// 复制比赛 
export async function copyCompetition(id) {
    console.log('api 复制比赛', id)
    return axios.post(`/api/competition/${id}/copy`)
}
// 晋级比赛
export async function promotionCompetition(id) {
    console.log('api 晋级比赛', id)
    return axios.post(`/api/competition/${id}/promotion`)
}

// 删除比赛
export async function deleteCompetition(id) {
    console.log('api 删除比赛', id)
    return axios.delete(`/api/competition/${id}`)
}
// 转为正式比赛
export async function competitionOfficial(id) {
    console.log('api 转为正式比赛', id)
    return axios.patch(`/api/competition/${id}/official`)
}


// 统计结果列表
export async function getStatisticsData(competitionId) {
    console.log('api 统计结果列表', competitionId)
    return axios.get(`/api/competition/${competitionId}/result/statistics`)
}
// 统计结果重置
export async function resetStatisticsData(competitionId) {
    console.log('api 统计结果重置', competitionId)
    return axios.patch(`/api/competition/${competitionId}/clean/score`)
}
// 统计结果导出 
export async function exportStatisticsData(competitionId) {
    console.log('api 统计结果导出', competitionId)
    return axios.get(`/api/competition/${competitionId}/result/statistics/export`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        //params,
        responseType: 'blob',
        paramsSerializer: function (p) {
            return qs.stringify(p, { arrayFormat: 'repeat' })
        }
    })
}
// 统计结果详情
export async function getStatisticsDetail(competitionId, programId) {
    console.log('api 统计结果详情', competitionId, programId)
    return axios.get(`/api/competition/${competitionId}/result/detail/program/${programId}`)
}


// 获取单个比赛下已通过审核的评委
export async function getJudgeApproved(competitionId) {
    console.log('api 获取单个比赛下已通过审核的评委', competitionId)
    return axios.get(`/api/competition/${competitionId}/judge/approved`)
}
// 检查裁判是否已经扫码或存在 
export async function judgePhoneCheck(data) {
    const { competitionId, phoneNumber } = data
    console.log(`api 检查裁判是否已经扫码或存在 competitionId=${competitionId}`, phoneNumber)
    return axios.get(`/api/competition/${competitionId}/judge/check`, { params: { phoneNumber } })
}
// 评委新增
export async function addJudge(competitionId, data) {
    console.log('api 评委新增', competitionId, data)
    return axios.post(`/api/competition/${competitionId}/judge`, data)
}
// 从其他比赛中导入评委
export async function importJudgeFromBefore(competitionId, data) {
    console.log('api 从其他比赛中导入评委', competitionId, data)
    return axios.post(`/api/competition/${competitionId}/judge/from/before`, data)
}
// excel导入评委
export async function importJudgeFromExcel(competitionId, data) {
    console.log('api excel导入评委', competitionId, data)
    return axios.post(`/api/competition/${competitionId}/judge/from/excel`, data)
}
// 审核不需要比对的评委
export async function auditCommonJudge(competitionId, judgeId, data) {
    console.log('api 审核不需要比对的评委', competitionId, judgeId, data)
    return axios.patch(`/api/competition/${competitionId}/judge/${judgeId}/approve?approve=${data.approve}&groupId=${data.groupId}`)
}
// 评委批量分组  
export async function judgeJoinGroup(competitionId, data) {
    console.log('api 评委批量分组', competitionId, data)
    return axios.patch(`/api/competition/${competitionId}/judge/join/group`, data)
}
// 评委列表
export async function getJudgeList(competitionId, params) {
    console.log('api 评委列表', competitionId, params)
    return axios.get(`/api/competition/${competitionId}/judge/page`, { params })
}
// 评委修改 
export async function updateJudge(competitionId, id, data) {
    console.log('api 评委修改', competitionId, id, data)
    return axios.patch(`/api/competition/${competitionId}/judge/${id}`, data)
}
// 上移裁判
export async function judgeNumberUp(competitionId, id) {
    console.log('api 上移裁判', competitionId, id)
    return axios.patch(`/api/competition/${competitionId}/judge/${id}/move/up`)
}
// 下移裁判
export async function judgeNumberDown(competitionId, id) {
    console.log('api 下移裁判', competitionId, id)
    return axios.patch(`/api/competition/${competitionId}/judge/${id}/move/down`)
}
// 评委删除
export async function deleteJudge(competitionId, ids) {
    console.log('api 评委删除', competitionId, ids)
    return axios.delete(`/api/competition/${competitionId}/judge/${ids.join(',')}`)
}
// 钉钉通讯录导入
export async function importJudgeFromAddressBook(competitionId, data) {
    console.log('api 钉钉通讯录导入', competitionId, data)
    return axios.post(`/api/competition/${competitionId}/judge/from/address/book`, data)
}

// 评委重新排序
export async function judgeReorder(competitionId) {
    console.log('api 评委重新排序', competitionId)
    return axios.patch(`/api/competition/${competitionId}/judge/reorder`)
}

// 评委分组列表
export async function getJudgeGroupList(competitionId) {
    console.log('api 评委分组列表', competitionId)
    return axios.get(`/api/competition/${competitionId}/judge/group/list`)
}
// 评委分组编辑
export async function updateJudgeGroup(competitionId, data) {
    console.log('api 评委分组编辑', competitionId, data)
    return axios.patch(`/api/competition/${competitionId}/judge/group`, data)
}


// 项目列表
export async function getPojectList(competitionId) {
    console.log('api 项目列表', competitionId)
    return axios.get(`/api/competition/${competitionId}/program/group/list`)
}
// 项目新增
export async function addProject(competitionId, data) {
    console.log('api 项目新增', competitionId, data)
    return axios.post(`/api/competition/${competitionId}/program`, data)
}
// 从其他比赛中导入项目
export async function importProjectFromBefore(competitionId, data) {
    console.log('api 从其他比赛中导入项目', competitionId, data)
    return axios.post(`/api/competition/${competitionId}/program/from/before`, data)
}
// excel导入项目
export async function importProjectFromExcel(competitionId, data) {
    console.log('api excel导入项目', competitionId, data)
    return axios.post(`/api/competition/${competitionId}/program/from/excel`, data)
}
// 项目修改
export async function updateProject(competitionId, id, data) {
    console.log('api 项目修改', competitionId, id, data)
    return axios.patch(`/api/competition/${competitionId}/program/${id}`, data)
}
// 项目排序
export async function updateProjectSort(competitionId, data) {
    console.log('api 项目排序', competitionId, data)
    return axios.patch(`/api/competition/${competitionId}/program/sort`, data)
}
// 项目删除
export async function deleteProject(competitionId, ids) {
    console.log('api 项目删除', competitionId, ids)
    return axios.delete(`/api/competition/${competitionId}/program/${ids.join(',')}`)
}
// 项目组增删改
export async function updateProjectGroup(competitionId, data) {
    console.log('api 项目组增删改', competitionId, data)
    return axios.patch(`/api/competition/${competitionId}/program/group`, data)
}

// 查询点数
export async function getUserPoint() {
    console.log('api 查询点数')
    return axios.get('/api/point')
}
// 点数充值记录
export async function getPointRecharge() {
    console.log('api 点数充值记录')
    return axios.get('/api/point/recharge/record')
}
// 点数消费记录
export async function getPointUse() {
    console.log('api 点数消费记录')
    return axios.get('/api/point/consume/record')
}
// 用户信息
export async function getCurrentUser() {
    console.log('api 用户信息')
    return axios.get('/api/user')
}
// 修改密码
export async function updateUserPassword(data) {
    console.log('api 修改用户密码', data)
    return axios.patch('/api/user/password', data)
}
// 获取钉钉通讯录
export async function getAddressBook(corpid, deptId) {
    console.log('api 获取通讯录')
    return axios.get(`/api/ding/auth/${corpid}/${deptId}/address/book`)
}

// 子账号列表 
export async function getSubUser() {
    console.log('api 子账号列表')
    return axios.get('/api/user/sub')
}
// 创建子账号
export async function addSubUser(data) {
    console.log('api 创建子账号', data)
    return axios.post('/api/user/sub', data)
}
// 主账号删除子账号
export async function deleteSubUser(ids) {
    console.log('api 主账号删除子账号')
    return axios.delete(`/api/user/sub/${ids}`)
}
// 修改子账号
export async function updateSubUser(id, data) {
    console.log('api 修改子账号', id, data)
    return axios.patch(`/api/user/sub/${id}`, data)
}
// 子账号脱离主账号
export async function detachMainUser() {
    console.log('api 子账号脱离主账号')
    return axios.patch('api/user/detach')
}

// 兑换点数
export async function pointVoucher(data) {
    console.log('api 兑换点数', data)
    return axios.post('/api/point/voucher', data)
}
// 点数套餐
export async function getPointPlan() {
    console.log('api 点数套餐')
    return axios.get('/api/point/plan')
}
// 点数套餐支付二维码
export async function getPointPlanQRCode(id) {
    console.log('api 点数套餐支付二维码', id)
    return axios.get(`/api/point/order/plan/${id}/alipay/qrcode`)
}
// 退款
export async function refundAlipay(tradeNo) {
    console.log('api 退款', tradeNo)
    return axios.patch(`/api/point/order/${tradeNo}/alipay/refund`)
}
// 跳转网页支付
export async function alipayWeb(tradeNo) {
    console.log('api 跳转网页支付', tradeNo)
    return axios.get(`/api/point/order/${tradeNo}/alipay/web`)
}
// 从通讯录添加子账号
export async function addSubUserFromDing(data) {
    console.log('api 从通讯录添加子账号', data)
    return axios.post('/api/ding/auth/sub', data)
}
// 开票 
export async function applyInvoice(tradeNo, data) {
    console.log('api 开票', tradeNo, data)
    return axios.post(`/api/point/trade/${tradeNo}/invoice`, data)
}
// 发票抬头和纳税人识别号
export async function getInvoiceTitle(params) {
    console.log('api 发票抬头', params)
    return axios.get('/api/point/invoice/title/number', { params })
}

export async function exportScore(competitionId, params) {
    console.log('api 导出分数', competitionId, params)
    return axios.get(`/api/competition/${competitionId}/result/statistics/export`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        params,
        responseType: 'blob',
        paramsSerializer: function (p) {
            return qs.stringify(p, { arrayFormat: 'repeat' })
        }
    })
}



import axios from './instance'

// 评委状态
export async function ddGetJudgeStatus(competitionId) {
    console.log('api 评委状态', competitionId)
    return axios.get(`/api/competition/${competitionId}/judge/status`)
}
// 评委报名 
export async function ddApplyFromPhone(competitionId) {
    console.log('api 评委报名', competitionId)
    return axios.post(`/api/competition/${competitionId}/judge/sign-up`)
}
// 直通码报名
export async function ddApplyFromCode(competitionId, code) {
    console.log(`api 直通码报名 competitionId=${competitionId} code=${code}`)
    return axios.patch(`/api/competition/${competitionId}/judge/directly/approve/${code}`)
}

// 更新用户信息 
export async function ddUpdateUserInfo(params) {
    console.log('api 更新用户信息', params)
    return axios.patch('/api/user', { ...params })
}


// 裁判签到 
export async function ddJudgeSignIn(competitionId) {
    console.log('api 评委签到', competitionId)
    return axios.patch(`/api/competition/${competitionId}/judge/sign-in`)
}
// 获取作为裁判的比赛列表
export async function ddGetJudgeableList() {
    console.log('api 获取作为裁判的比赛列表')
    return axios.get('/api/group/competition/list')
}
// 通过authCode获取用户信息
export async function getAuthMobile(params) {
    console.log('api 通过authCode获取用户信息', params)
    return axios.get('/api/ding/auth/mobile', { params })
}
// 评委信息 
export async function ddGetJudgeInfo(competitionId) {
    console.log('api 获取评委信息', competitionId)
    return axios.get(`/api/competition/${competitionId}/judge`)
}
// 评委打分细项
export async function ddJudgeScore(params) {
    const { id: competitionId, programId, itemId, score } = params
    console.log('手机评委打分', params)
    return axios.post(`/api/competition/${competitionId}/judge/score/program/${programId}/item/${itemId}?score=${score}`)
}
// 评委打分细项统一
export async function ddJudgeScoreAll(params) {
    const { id: competitionId, programId, data } = params
    console.log('手机评委打分', params)
    return axios.post(`/api/competition/${competitionId}/judge/score/program/${programId}`, data)
}

export async function backoutSignUp(competitionId) {
    console.log('api 撤销报名', competitionId)
    return axios.delete(`/api/competition/${competitionId}/judge/sign-up`)
}

import { message } from 'antd'
import * as CryptoJS from 'crypto-js'
import * as dd from 'dingtalk-jsapi'
import moment from 'moment'
import { getENV } from 'dingtalk-jsapi/lib/env'
import { compareVersion } from 'dingtalk-jsapi/lib/sdk/sdkLib'

const designWidth = 1920
const designHeight = 1080

const ddWidth = 749
const ddHeight = 1250

export function vw(px) {
  let size = (100 / designWidth) * px
  return `${size}vw`
}

export function vh(px) {
  let size = (100 / designHeight) * px
  return `${size}vh`
}

export function dvw(px) {
  let size = (100 / ddWidth) * px
  return `${size}vw`
}

export function dvh(px) {
  let size = (100 / ddHeight) * px
  return `${size}vh`
}

export function vwToPx(vw, w = window.innerWidth) {
  vw = typeof vw === 'string' ? +vw.replace('vw', '') : vw
  let pxSize = (vw / 100) * w
  return pxSize
}

export function vhToPx(vh, h = window.innerHeight) {
  vh = typeof vh === 'string' ? +vw.replace('vh', '') : vh
  let pxSize = (vh / 100) * h
  return pxSize
}

//复制到浏览器运行
function getCode() {
  function code() {
    var a = '0123456789abcdefghjkmnpqrstuvwxyz'
    return a[Math.ceil(Math.random() * (a.length - 1))]
  }
  var length = 6 //字符长度
  return new Array(length)
    .fill('')
    .map(() => code())
    .join('')
}

export function getPublicUri() {
  return window.location.pathname.split('/')[1]
}

export function GUID() {
  function s() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return [[s(), s()].join(''), s(), s(), s(), [s(), s(), s()].join('')].join('-')
}

export function getPageFromUI(pagination = {}) {
  const { page = 1, size = 10 } = pagination
  return { page: page - 1, size }
}

export function getPageFromServer(data) {
  const page = data.pageable.pageNumber + 1
  const size = data.pageable.pageSize
  const total = data.totalElements
  // const { pageNumber = 0, pageSize, total } = pagination
  return { page, size, total }
}

export function settingShowError(action, err = {}) {
  const detail = err.response && err.response.data && err.response.data.detail ? err.response.data.detail : null
  const status = err.response && err.response.status ? err.response.status : null
  if (status) {
    message.error(`${action}失败 错误码：${status}${detail ? ` 原因：${detail}` : ''}`)
  }
}

export function encrypt(str, key) {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const result = CryptoJS.AES.encrypt(str, keyHex, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()
  return result
}

export function decrypt(str, key) {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  let decrypt = CryptoJS.AES.decrypt(str, keyHex, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

export function getEnvironment() {
  let result = null
  if (dd.pc) {
    result = 'dd-pc'
  } else if (dd.android || dd.ios) {
    result = 'dd-phone'
  } else {
    result = 'web'
  }
  return result
}
/**
 * 判断钉钉当前版本
 * @return {boolean}
 */
export function isAuthSDKSupport(v) {
  const { version } = getENV()
  return compareVersion(version, v)
}
// (countdownTime || nowTime) + countdown - nowTime = 显示倒计时
export function getCountdown({
  countdownTime, // 10位时间戳
  countdown // seconds
}) {
  if (!countdown) {
    return
  }
  const sendTime = moment.unix(countdownTime ? countdownTime : moment().unix(), 'YYYY-MM-DD HH:mm:ss')
  const now = moment()
  const remain = sendTime.diff(now, 'seconds')
  countdown = +countdown
  const limit = remain + countdown
  return limit
}

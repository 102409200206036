import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './theme.less'
import store from './store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Route } from 'react-router'
import { ConfigProvider, Spin } from 'antd'
import 'antd/dist/antd.css'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { AliPay } from 'components/setting/voucherCenter'
import NewControlPanel from './containers/control/NewControlPanel'
const SettingLayout = lazy(() => import('./containers/setting/SettingLayout'))
const MatchPage = lazy(() => import('./containers/setting/MatchPage'))
const MatchSettingLayout = lazy(() => import('./containers/setting/MatchSettingLayout'))
const AddMatch = lazy(() => import('./containers/setting/AddMatch'))
const HomePage = lazy(() => import('./containers/setting/HomePage/index'))
const UserLayout = lazy(() => import('./containers/setting/UserLayout'))
const BigScreen = lazy(() => import('./containers/web'))
const Dd = lazy(() => import('./containers/dd'))
const ExternalLogin = lazy(() => import('./containers/login/ExternalLogin'))
const InternalLogin = lazy(() => import('./containers/login/InternalLogin'))
const WebLoginPage = lazy(() => import('./containers/login/WebLoginPage'))
const JudgeApplyRedirectPage = lazy(() => import('./containers/login/JudgeApplyRedirectPage'))
const DdPcLogin = lazy(() => import('./containers/login/DdPcLogin'))
const ControlLoginPage = lazy(() => import('./containers/control/ControlLoginPage'))
const RegisterPage = lazy(() => import('./containers/login/RegisterPage'))
const VoucherCenterPage = lazy(() => import('./containers/setting/VoucherCenterPage'))
const BasicSetting = lazy(() => import('./containers/setting/BasicSetting'))
const ScoreSetting = lazy(() => import('./containers/setting/ScoreSetting'))
const JudgeSetting = lazy(() => import('./containers/setting/JudgeSetting'))
const ProjectSetting = lazy(() => import('./containers/setting/ProjectSetting'))
const Statistics = lazy(() => import('./containers/setting/Statistics'))
const InvitationPage = lazy(() => import('./containers/setting/InvitationPage'))
// const NewControlPanel = lazy(() => import('./containers/control/NewControlPanel'))
const MobileControlPanel = lazy(() => import('./containers/control/MobileControlPanel'))
const WebLoginFromDing = lazy(() => import('./containers/login/WebLoginFromDing'))
const ScanQRCodes = lazy(() => import('./containers/login/ScanQRCodes'))

moment.locale('zh-cn')

if (process.env.NODE_ENV === 'development') {
  require('./api/mocks').default.bootstrap()
}

const SuspenseFallback = ({ width, height }) => {
  return (
    <div style={{ width, height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin tip={'页面加载中'} />
    </div>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      {/* <Router basename={getPublicUri()}>
        <Switch>
          <Route path="/openapp" component={Login} />
          <Route path="/web" component={Web} />
          <Route path="/dd" component={Dd} />
        </Switch>
      </Router>*/}
      <Router>
        <Suspense fallback={<SuspenseFallback width={'100vw'} height={'100vh'} />}>
          <Switch>
            <Route path='/' render={(props) => <HomePage {...props} current={'home'} />} exact />
            {/* <Route path='/web/relation' render={(props) => <Home {...props} current={'relation'} />} exact /> */}
            {/* 评委扫码报名跳转页 */}
            <Route path='/web/judge/apply' component={JudgeApplyRedirectPage} />
            {/* 登录 */}
            <Route path='/web/login' component={WebLoginPage} />
            <Route path='/web/scan/QR/codes' component={ScanQRCodes} />
            <Route path='/web/external/login' component={ExternalLogin} />
            <Route path='/web/app/index' component={InternalLogin} />
            <Route path='/web/dd/pc/login' component={DdPcLogin} />
            <Route path='/web/qrcode/login' component={WebLoginFromDing} />
            {/* 注册 */}
            <Route path='/web/register' component={RegisterPage} />
            {/* 大屏 */}
            <Route path='/web/match/:competitionId' component={BigScreen} exact />
            {/* 控制台*/}
            <Route path='/web/control/login/:competitionId' component={ControlLoginPage} exact />

            {/* 控制台 PC 新*/}
            <Route path='/web/control/panel/:competitionId' component={NewControlPanel} exact />
            {/* 控制台 手机*/}
            <Route path='/web/mobile/control/panel/:competitionId' component={MobileControlPanel} exact />
            {/* 钉钉页面 */}
            <Route path='/web/dd/:competitionId' component={Dd} exact />
            <Route path='/web/aliPay' component={AliPay} exact />
            {/* 后台管理 */}
            <SettingLayout showSider={false}>
              <Suspense fallback={<SuspenseFallback width={'100vw'} height={'calc(100vh - 74px)'} />}>
                <Route path='/web/add/match' component={AddMatch} exact />
                <Route path='/web/setting/backstage' component={MatchPage} exact />
                <Route path='/web/backstage/:id/:mode' render={(props) => (
                  <MatchSettingLayout {...props}>
                    <Suspense fallback={<SuspenseFallback width={'calc(100vw - 252px)'} height={'calc(100vh - 74px)'} />}>
                      <Route path='/web/backstage/:id/:mode/basic' component={BasicSetting} exact />
                      <Route path='/web/backstage/:id/:mode/judge' component={JudgeSetting} exact />
                      <Route path='/web/backstage/:id/:mode/score' component={ScoreSetting} exact />
                      <Route path='/web/backstage/:id/:mode/project' component={ProjectSetting} exact />
                      <Route path='/web/backstage/:id/:mode/statistics' component={Statistics} exact />
                      <Route path='/web/backstage/:id/:mode/invitation' component={InvitationPage} exact />
                    </Suspense>
                  </MatchSettingLayout>
                )} />
                <Route path='/web/user' component={UserLayout} />
                <Route path='/web/setting/voucher' component={VoucherCenterPage} exact />
              </Suspense>
            </SettingLayout>
          </Switch>
        </Suspense>
      </Router>
    </ConfigProvider>
  </Provider >,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import GroupPicker from './GroupPicker'
import ItemSelect from './ItemSelect'

const ToolsBarWrapper = styled.div`
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
`

const ToolsBar = ({
    loaded,
    groupReadOnly,
    groupDataSource,
    groupOnOk,
    groupInitialValue,
    itemOptions,
    itemValue,
    itemOnChange,
    itemVisible,
    isBattle
}) => {
    return (
        <ToolsBarWrapper>
            {loaded && (
                <>
                    <GroupPicker
                        readOnly={groupReadOnly}
                        dataSource={groupDataSource}
                        onOk={groupOnOk}
                        initialValue={groupInitialValue}
                    />
                    {!isBattle && itemVisible && (
                        <ItemSelect
                            options={itemOptions}
                            value={itemValue}
                            onChange={itemOnChange}
                        />
                    )}
                </>
            )}
        </ToolsBarWrapper>
    )
}

export default ToolsBar

import { combineReducers } from 'redux-immutable'
import web from './web'
import dd from './dd'
import sys from './sys'
import setting from './setting'
import control from './control'

export const rootReducer = combineReducers({
    web,
    dd,
    sys,
    setting,
    control
})
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { immutableGet, rawJs } from '../../common/selectorTools'
import { actions, selectors } from '../../ducks/control'
import styled, { css } from 'styled-components'
import { Input, InputNumber, message, Modal, Popconfirm, Table } from 'antd'
import { vw, vh } from 'common/utils'
import ToolsBar from './ToolsBar/index'

const tableBorderColor = '#024B91' // 表格边框颜色
const tBgColor = '#05244B' // 表格背景色
const tHeaderBgColor = '#005DAB' // 表头背景色
const tHeaderFontColor = '#CCCCCC' // 表头字体颜色

const FlexDiv = styled.div`
    display: flex;
    aling-items: center;
    justify-content: center;
    flex-wrap: wrap;
`

const ITable = styled(Table)`
    .ant-table {
        background: ${tBgColor};
    }

    .ant-table .ant-table-thead > tr > th {
        background-color: ${tHeaderBgColor} !important;
        border-color: ${tHeaderBgColor} !important;
    }

    .ant-table-tbody > tr.row-active > td,
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: #051838;

    }

    .ant-table-thead {
        .ant-table-cell:not(.ant-table-selection-column) {
            color: ${tHeaderFontColor};
            padding: 11px 16px;
            font-size: ${14}px;
        }
    }

    .ant-table-tbody {
        .ant-table-cell {
            color: #FFFFFF;
            font-size: ${14}px;
        }
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th {
        border-color: ${tableBorderColor};
    }

    .ant-table-cell-scrollbar {
        box-shadow: none;
    }

    .ant-table-tbody > tr:not(:last-child) > td {
        border-bottom: 1px dashed white;
    }

    .ant-table-body {
        &::-webkit-scrollbar-track-piece {
          border-right: 1px solid  ${tableBorderColor};
          border-bottom: 1px solid  ${tableBorderColor};
          border-left: none;
        }

        &::-webkit-scrollbar-thumb {
            background: #FFFFFF;
        }
    }

    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background: ${tBgColor};
    }
`

const OperateBtn = styled.div`
    // width: 64px;
    height: 30px;
    line-height: 28px;
    background: ${props => !props.disabled ? '#011335' : 'rgba(91,118,138,0.4)'};
    border: 1px solid ${props => !props.disabled ? '#4E8BB9' : 'rgba(91,118,138,0.4)'};
    border-radius: 4px;
    position: relative;
    margin: 0 4px;
    font-size: 14px;
    font-weight: 400;
    cursor: ${props => !props.disabled ? 'pointer' : 'not-allowed'} ;
    color: ${props => !props.disabled ? '#B0DDFF' : '#5B768A'};
    text-align: center;
    padding: 0 8px;
    margin: 4px;

    &.active {
        background: linear-gradient(0deg, rgba(0,44,118,0.45), rgba(0,198,255,0.45));
        border-color: #007BDC;
    }

    ${props => !props.disabled && css`
        &:hover {
            border: 1px solid #27A3FF;
            color: #27A3FF;
        }
    `}
`

const EmptyText = styled.div`
    color: white;
    padding: 50px 0;
    font-size: 24px;
`

const Circle = styled.div`
    // width: 40px;
    // height: 40px;
    border: 2px solid red;
    // border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

class ProjectGroupList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            columns: [
                {
                    title: '序号',
                    dataIndex: 'sort',
                    align: 'center',
                    width: 80,
                    render: (sort, record) => {
                        if (this.state.mode === 'BATTLE' && record.finalScore === '1.00') {
                            return <Circle>{sort}</Circle>
                        }
                        return sort
                    }
                },
                { title: '项目名称', dataIndex: 'programName', align: 'center' },
                { title: '参赛者', dataIndex: 'contestant', align: 'center' },
                {
                    title: '分数',
                    dataIndex: 'programItemResults',
                    align: 'center',
                    width: 80,
                    render: (data, record) => {
                        const { activeItemId, enableItem, enableScoreItemCombine, mode } = this.state
                        if (mode === 'BATTLE') {
                            return record.currentScore
                        }
                        if (enableItem && !enableScoreItemCombine) {
                            if (data && activeItemId) {
                                const cur = data.find(item => item.itemId == activeItemId)
                                if (cur) {
                                    return cur.score
                                }
                                return 0
                            }
                        } else {
                            return record.finalScore
                        }
                    }
                },
                {
                    title: '违规',
                    dataIndex: 'programFoulRecords',
                    align: 'center',
                    type: 'BATTLE',
                    render: (data, record) => {
                        const disabled = record.disabled || !this.state.startRowId.includes(record.id)
                        return (
                            <FlexDiv>
                                {this.state.fouls.map(({ id, foulName }) => {
                                    const cur = record.programFoulRecords ? record.programFoulRecords.find(item => item.foulId === id) : null
                                    return (
                                        <Popconfirm
                                            key={id}
                                            disabled={disabled}
                                            title={`确定【${record.programName}】【${foulName}】次数+1吗？`}
                                            onConfirm={this.handleBattleFoul.bind(this, record, id)}
                                        >
                                            <OperateBtn disabled={disabled}>
                                                {foulName}
                                                {cur && cur.times ? `(${cur.times})` : ''}
                                            </OperateBtn>
                                        </Popconfirm>
                                    )
                                })}
                            </FlexDiv>
                        )
                    }
                },
                {
                    title: '打分',
                    dataIndex: 'score',
                    align: 'center',
                    type: 'BATTLE',
                    render: (data, record) => {
                        const { convenientScore, startRowId } = this.state
                        const disabled = record.disabled || !startRowId.includes(record.id)
                        return (
                            <FlexDiv>
                                {convenientScore && convenientScore.split(',').map(point => (
                                    <Popconfirm
                                        key={point}
                                        title={`确定【${record.programName}】分数+【${point}】吗？`}
                                        onConfirm={this.handleBattlePoint.bind(this, record, point, null)}
                                        disabled={disabled}
                                    >
                                        <OperateBtn disabled={disabled}>
                                            {point}
                                        </OperateBtn>
                                    </Popconfirm>
                                ))}
                                <OperateBtn
                                    disabled={disabled}
                                    onClick={() => {
                                        if (disabled) { return }
                                        this.setState({ customPointVisible: true, currentRecord: record })
                                    }}
                                >
                                    自定义
                                </OperateBtn>
                            </FlexDiv>
                        )
                    }
                },
                {
                    title: '结果',
                    dataIndex: 'determine',
                    align: 'center',
                    width: 90,
                    type: 'BATTLE',
                    render: (data, record) => {
                        const disabled = record.disabled || !this.state.startRowId.includes(record.id)
                        return (
                            <Popconfirm
                                title={`确定给【${record.programName}】判胜吗？`}
                                onConfirm={this.handleBattleDetermine.bind(this, record.id, record.battleProgram)}
                                disabled={disabled}
                            >
                                <OperateBtn disabled={disabled}>
                                    判胜
                                </OperateBtn>
                            </Popconfirm>
                        )
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'operate',
                    align: 'center',
                    width: 180,
                    render: (_, record, i) => {
                        const { isBattle } = this.state
                        return {
                            children: this.renderOperate(record),
                            props: isBattle ? { rowSpan: i % 2 !== 0 ? 0 : 2 } : {}
                        }
                    }
                }
            ],
            subItemList: [], // 分项列表
            rowId: null, // 表格选中行
            startRowId: [],
            activeItemId: null, // 当前分项
            fouls: [],
            convenientScore: '',
            mode: '',
            scoreResult: { programId: null },
            currentRecord: {},
            programGroupsMap: {}
        }
    }

    componentDidMount() {
        this.fetchData()
        this.handleSetDefaultState()
    }

    renderOperate = (record) => {
        const { battleStatus, mode, activeBattleId } = this.state
        const { matchInfo = {} } = this.props
        const { setting = {} } = matchInfo
        const { disabled } = record
        const options = [
            {
                title: '宣讲',
                event: 'SPEECH',
                type: ['SCORE', 'VOTE']
            },
            {
                title: '打分',
                event: 'SCORE',
                type: ['SCORE', 'VOTE']
            },
            {
                title: '亮分',
                event: 'SHOW_SCORE',
                type: ['SCORE', 'VOTE']
            },
            {
                title: '成绩',
                event: 'SHOW_RESULT',
                type: ['SCORE', 'VOTE']
            },
            {
                title: '开始',
                event: 'BATTLE_START',
                type: ['BATTLE'],
                onConfirm: this.handleBattleStart.bind(this, record)
            },
            {
                title: battleStatus === 'pause' ? '继续' : '暂停',
                event: 'BATTLE_PAUSE',
                type: ['BATTLE'],
                onConfirm: this.handlePauseOrResume,
                hide: (activeBattleId === record.id ? (!setting.enableCountdown ? true : false) : true)
            }
        ]

        return (
            <FlexDiv>
                {options
                    .filter(item => item.type.findIndex(val => val === mode) !== -1 && !item.hide)
                    .map(({ title, event, onConfirm }) => {
                        if (onConfirm) {
                            return (
                                <Popconfirm
                                    key={event}
                                    title={`确定【${title}】对决吗？`}
                                    onConfirm={onConfirm}
                                    disabled={disabled}
                                >
                                    <OperateBtn disabled={disabled}>
                                        {title}
                                    </OperateBtn>
                                </Popconfirm>
                            )
                        } else {
                            return (
                                <OperateBtn
                                    key={event}
                                    disabled={disabled}
                                    onClick={this.handleEventOnChange.bind(this, event, record, disabled)}
                                >
                                    {title}
                                </OperateBtn>
                            )
                        }
                    })}
            </FlexDiv>
        )
    }

    handleSetResult = (scoreResult) => {
        const { programId, itemId, data } = scoreResult
        const {
            activeProgramGroupId,
            programGroupsMap,
            enableItem,
            enableScoreItemCombine,
        } = this.state
        const curGroup = programGroupsMap[activeProgramGroupId]
        const newData = {
            ...programGroupsMap,
            [activeProgramGroupId]: {
                ...curGroup,
                programs: [
                    ...curGroup.programs.map(item => {
                        if (item.id === programId) {
                            if (enableItem) {
                                const programScore = enableItem && !enableScoreItemCombine ? data.itemScore : data.programScore
                                if (item.programItemResults && item.programItemResults.find(v => v.itemId == itemId)) {
                                    item.programItemResults = item.programItemResults.map(val => {
                                        if (val.itemId == itemId) {
                                            val.score = data.itemScore
                                        }
                                        return val
                                    })
                                } else {
                                    if (!item.programItemResults) {
                                        item.programItemResults = []
                                    }
                                    item.programItemResults.push({
                                        itemId,
                                        score: programScore
                                    })
                                }
                            } else {
                                item.finalScore = data.programScore
                            }
                        }
                        return item
                    })
                ]
            }
        }
        this.setState({
            programGroupsMap: { ...newData }
        })
    }

    handleSetDefaultState = () => {
        const { currentProjects } = this.props
        const cur = currentProjects && currentProjects[0]
        if (cur && cur.id) {
            const { event = '' } = cur
            let currentOperate = null
            if (event.indexOf('SPEECH') !== -1) {
                currentOperate = 'SPEECH'
            } else if (event.indexOf('SHOW_SCORE') !== -1) {
                currentOperate = 'SHOW_SCORE'
            } else if (event.indexOf('SCORE') !== -1) {
                currentOperate = 'SCORE'
            } else if (event.indexOf('SHOW_RESULT') !== -1) {
                currentOperate = 'SHOW_RESULT'
            }
            this.setState({
                rowId: [cur.id],
                currentOperate,
                battleStatus: ['TEST_PAUSE', 'PAUSE'].includes(event) ? 'pause' : (['TEST_RESUME', 'RESUME'].includes(event) ? 'resume' : null)
            })
        }
    }

    fetchData = () => {
        this.handleSetData()
    }

    handleSetData = () => {
        const { isTest, matchInfo = {} } = this.props
        let {
            programGroups,
            testPrograms,
            programGroupsMap,
            activeProgramGroupId,
            items = [],
            activeItemId,
            activeProgramId,
            setting = {},
            fouls,
            link
        } = matchInfo
        const { enableItem, enableScoreItemCombine, mode, convenientScore } = setting
        if (!activeProgramGroupId) {
            this.handleGroupOnChange(programGroups[0])
        }
        if (!isTest) {
            activeProgramGroupId = activeProgramGroupId || (programGroups ? programGroups[0].id : null)
        }
        const firstItemId = items && items[0] ? items[0].id : null
        const rowId = [activeProgramId]
        if (mode === 'BATTLE') {
            rowId.push(activeItemId)
        }
        this.setState({
            groupOptions: programGroups.map(({ id, groupName, sort }) => ({ id, groupName, sort })),
            testPrograms,
            programGroupsMap: {
                ...programGroupsMap,
                testPrograms: {
                    id: 'testPrograms',
                    groupName: '测试项目组',
                    programs: testPrograms
                }
            },
            subItemList: items,
            activeItemId: activeItemId && items.some(item => item.id === activeItemId) ? `${activeItemId}` : `${firstItemId}`,
            activeProgramGroupId: isTest ? 'testPrograms' : activeProgramGroupId,
            enableItem,
            enableScoreItemCombine,
            rowId,
            loaded: true,
            loading: false,
            isBattle: mode === 'BATTLE',
            fouls,
            activeBattleId: activeProgramId,
            convenientScore,
            mode,
            startRowId: ['PROGRAM_LIST', 'TEST'].includes(link) ? [] : rowId
        })
    }

    handleSetTableScrollTop = () => {
        const tBody = document.getElementsByClassName('ant-table-body')[0]
        if (tBody) {
            tBody.scrollTop = 0
        }
    }

    handleSetRowId = (rowId) => {
        this.setState({
            rowId: [...rowId]
        })
    }

    handleEventOnChange = (event, record, disabled) => {
        if (disabled) {
            message.warning('彩排中 当前项目不可操作')
            return
        }
        const { competitionId, isTest: test } = this.props
        const { activeItemId: itemId } = this.state
        const func = {
            SPEECH: 'controlProjectOnSpeech',
            SCORE: 'controlProjectScoreStart',
            SHOW_SCORE: 'controlProjectScoreEnd',
            SHOW_RESULT: 'controlProjectShowResult'
        }
        const programId = record.id
        this.handleSetRowId([programId])
        this.setState({ loading: true })
        this.props[func[event]](
            { competitionId, programId, itemId, test },
            (data) => {
                this.setState({
                    currentOperate: event,
                    loading: false
                })
                if (event === 'SHOW_RESULT') {
                    this.handleSetResult(data)
                }
                this.props.eventOnSuccess({ currentProjects: [record], event })
            },
            this.handleOnFail
        )
    }
    // 对决开始
    handleBattleStart = (record) => {
        const { competitionId } = this.props
        const payload = {
            programAId: record.id,
            programBId: record.battleProgram,
            test: this.props.isTest
        }
        if (!payload.programAId || !payload.programBId) {
            message.warning('缺少对决目标')
            return
        }
        this.setState({ loading: true })
        this.props.controlBattleStart(
            { competitionId, ...payload },
            () => {
                const { programGroupsMap, activeProgramGroupId } = this.state
                const cur = programGroupsMap[activeProgramGroupId]
                const newData = { // 犯规次数和分数重置
                    ...programGroupsMap,
                    [activeProgramGroupId]: {
                        ...cur,
                        programs: cur.programs.map(item => {
                            item.finalScore = null
                            item.currentScore = null
                            if (item.programFoulRecords) {
                                item.programFoulRecords = item.programFoulRecords.map(foul => {
                                    foul.times = 0
                                    return foul
                                })
                            }
                            return item
                        })
                    }
                }
                this.setState({
                    activeBattleId: record.id,
                    battleStatus: 'resume',
                    loading: false,
                    startRowId: [payload.programAId, payload.programBId],
                    programGroupsMap: { ...newData }
                })
                const programB = cur.programs.find(item => item.id === record.battleProgram)
                this.props.eventOnSuccess({ currentProjects: [record, programB] })
            },
            this.handleOnFail
        )
    }
    // 对决暂停/继续
    handlePauseOrResume = () => {
        const { battleStatus } = this.state
        const { competitionId, isTest: test } = this.props
        this.setState({ loading: true })
        const func = (!battleStatus || battleStatus === 'resume') ? 'controlBattlePause' : 'controlBattleResume'
        this.props[func](
            { competitionId, test },
            () => {
                this.setState({
                    battleStatus: (!battleStatus || battleStatus === 'resume') ? 'pause' : 'resume',
                    loading: false
                })
            },
            this.handleOnFail
        )
    }
    // 对决违规
    handleBattleFoul = (record, foulId) => {
        const { id: programId, battleProgram } = record
        const { competitionId, matchInfo = {} } = this.props
        const { setting } = matchInfo
        this.setState({ loading: true })
        this.props.controlBattleFoul(
            { competitionId, programId, foulId },
            () => { // 犯规次数计算 超出直接判负
                const { programGroupsMap, activeProgramGroupId, fouls } = this.state
                const cur = programGroupsMap[activeProgramGroupId]
                const curFoul = fouls.find(item => item.id === foulId)
                const max = curFoul ? curFoul.times : 0
                let isLose = false
                const newData = {
                    ...programGroupsMap,
                    [activeProgramGroupId]: {
                        ...cur,
                        programs: cur.programs.map(item => {
                            if (item.id === programId) {
                                if (!item.programFoulRecords) {
                                    item.programFoulRecords = []
                                }
                                const foulInx = item.programFoulRecords.findIndex(foul => foul.foulId === foulId)
                                if (foulInx !== -1) {
                                    const times = item.programFoulRecords[foulInx].times + 1
                                    item.programFoulRecords[foulInx] = {
                                        ...item.programFoulRecords[foulInx],
                                        times
                                    }
                                    if (times > max) {
                                        isLose = true
                                    }
                                } else {
                                    item.programFoulRecords = [
                                        ...item.programFoulRecords,
                                        { foulId, times: 1 }
                                    ]
                                }
                            }
                            return item
                        })
                    }
                }
                if (setting.enableExceededFoulTimes && isLose) {
                    message.info('犯规已超次数 即将自动判负')
                    this.handleBattleDetermine(battleProgram, programId)
                }
                this.setState({
                    loading: false,
                    programGroupsMap: { ...newData }
                })
            },
            this.handleOnFail
        )
    }
    // 对决打分
    handleBattlePoint = (record, point, success) => {
        if (!point) {
            message.warning('请输入分数')
            return
        }
        const { id: programId } = record
        const { competitionId, matchInfo = {} } = this.props
        this.setState({ loading: true })
        this.props.controlBattlePoint(
            { competitionId, programId, point: Number(point) },
            () => {
                const { programGroupsMap, activeProgramGroupId } = this.state
                const { setting = {} } = matchInfo
                const { enableLeadScore, leadScore } = setting
                const cur = programGroupsMap[activeProgramGroupId]
                let currentScore = 0 // currentScore实时分数 finalScore ='0.00'输 ='1.00'胜
                const newData = {
                    ...programGroupsMap,
                    [activeProgramGroupId]: {
                        ...cur,
                        programs: cur.programs.map(item => {
                            if (item.id === programId) {
                                if (!item.currentScore) {
                                    item.currentScore = 0
                                }
                                item.currentScore = Number(item.currentScore) + Number(point)
                                currentScore = item.currentScore
                            }
                            return item
                        })
                    }
                }
                const battleProgramRecord = cur.programs.find(item => item.id === record.battleProgram)
                const battleProgramCurrentScore = battleProgramRecord ? battleProgramRecord.currentScore : 0
                if (enableLeadScore && (currentScore - battleProgramCurrentScore >= leadScore)) {
                    message.info(`已领先${leadScore}分 即将自动判胜`)
                    this.handleBattleDetermine(programId, record.battleProgram)
                }
                this.setState({
                    loading: false,
                    programGroupsMap: { ...newData }
                })
                success && success()
            },
            this.handleOnFail
        )
    }
    // 对决判胜 A胜 B负
    handleBattleDetermine = (programAId, programBId) => {
        const { competitionId, isTest: test } = this.props
        this.setState({ loading: true })
        this.props.controlBattleDetermine(
            { competitionId, programAId, programBId, test },
            () => {
                this.setState({ loading: false })
            },
            this.handleOnFail
        )
    }

    handleOnFail = (err) => {
        const { onFail } = this.props
        this.setState({ loading: false })
        onFail && onFail(err)
    }

    handleSubItemOnChange = (activeItemId) => {
        this.setState({
            activeItemId,
            rowId: []
        })
        this.handleSetTableScrollTop()
    }

    handleGroupOnChange = (data) => {
        const { competitionId } = this.props
        this.props.controlProjectGroupSwitch(
            { competitionId, programGroupId: data.id },
            () => {
                this.setState({
                    activeProgramGroupId: data.id,
                    rowId: []
                })
                this.handleSetTableScrollTop()
            },
            () => {
                message.error('切换项目组失败')
            }
        )
    }

    handleCustomPointOnCancel = () => {
        this.setState({
            customPointVisible: false,
            customPoint: null
        })
    }

    renderBattleColumns = (data, dataIndex) => {
        return (
            <div>
                {data.map((item, i) => (
                    <div
                        key={item.id}
                        style={{
                            borderBottom: i === 0 ? '1px solid #fff' : 'none',
                            padding: 16
                        }}
                    >
                        {item[dataIndex]}
                    </div>
                ))}
            </div>
        )
    }

    render() {
        const {
            loading,
            loaded,
            columns,
            activeItemId,
            subItemList,
            enableItem,
            enableScoreItemCombine,
            isBattle,
            customPoint,
            currentRecord,
            customPointVisible,
            programGroupsMap,
            activeProgramGroupId,
            groupOptions
        } = this.state
        const { isTest } = this.props
        const groupAvailable = !isTest && loaded && groupOptions
        const curGroup = programGroupsMap[activeProgramGroupId]

        return (
            <div style={{ marginTop: vh(34), width: '100%' }}>
                <ToolsBar
                    loaded={loaded}
                    groupReadOnly={!groupAvailable}
                    groupDataSource={groupOptions}
                    groupOnOk={this.handleGroupOnChange}
                    groupInitialValue={curGroup ? curGroup : null}
                    itemOptions={subItemList}
                    itemValue={activeItemId}
                    itemOnChange={this.handleSubItemOnChange}
                    itemVisible={enableItem && !enableScoreItemCombine && !isTest}
                    isBattle={isBattle}
                />
                {loaded && !isBattle && <div style={{ color: '#fff' }}>注：项目【打分】结束后，必须点击【成绩】按钮才会计算最终分数</div>}
                <ITable
                    loading={loading}
                    dataSource={curGroup ? curGroup.programs : []}
                    columns={isBattle ? columns : columns.filter(item => item.type !== 'BATTLE')}
                    pagination={false}
                    bordered
                    scroll={{ y: vh(620) }}
                    rowKey={record => record.id}
                    locale={{ emptyText: <EmptyText>暂无数据</EmptyText> }}
                    onRow={(record, inx) => {
                        const { disabled } = record
                        return {
                            onClick: () => {
                                if (!disabled) {
                                    const ids = isBattle ? [record.id, record.battleProgram] : [record.id]
                                    this.handleSetRowId(ids)
                                }
                            }
                        }
                    }}
                    rowClassName={(record) => {
                        return this.state.rowId.includes(record.id) ? 'row-active' : ''
                    }}
                    className={isBattle ? 'battle-table' : ''}
                />
                <Modal
                    title={`打分【${currentRecord.programName}】`}
                    visible={customPointVisible}
                    centered
                    width={400}
                    onCancel={this.handleCustomPointOnCancel}
                    onOk={this.handleBattlePoint.bind(this, currentRecord, customPoint, this.handleCustomPointOnCancel)}
                    confirmLoading={loading}
                >
                    <InputNumber
                        placeholder='分数'
                        value={customPoint}
                        style={{ width: '100%' }}
                        onChange={(value) => {
                            this.setState({
                                customPoint: value
                            })
                        }}
                    />
                </Modal>
            </div>
        )
    }
}

const { controlSelector } = selectors

const mapStateToProps = createStructuredSelector({
    matchInfo: rawJs(immutableGet(controlSelector, 'controlCurrentMatch'))
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectGroupList)

import { createActions } from 'redux-actions'

const actions = createActions(
    {},
    'WEB_SET_CURRENT_MATCH_INFO',
    'WEB_SET_WS_DATA',
    'WEB_SET_CURRENT_MATCH_LINK_INFO'
)

export default { ...actions }

import { handleActions } from 'redux-actions'
import actions from './actions'
import { fromJS } from 'immutable'

export default handleActions(
  {
    [actions.controlSetCurrentMatch](state, { payload }) {
      if (payload) {
        const data = { ...payload }
        data.programsMap = {}
        data.programs = []
        data.programGroupsMap = {}
        data.itemsMap = {}
        data.programGroups = []
        data.testPrograms = []
        const isBattle = data.setting && data.setting.mode === 'BATTLE'
        const count = isBattle ? 2 : 3
        if (payload.programGroups) {
          data.programGroups = payload.programGroups.map(group => {
            if (group.programs) {
              const programLen = group.programs.length
              group.programs = group.programs.map((item, i) => {
                item.disabled = data.status === 'REHEARSAL' && (i >= count && (i < (programLen - (isBattle && programLen % 2 !== 0 ? (count + 1) : count)))) ? true : false
                if (isBattle) {
                  if (i % 2 === 0) {
                    if (group.programs[i + 1]) {
                      item.battleProgram = group.programs[i + 1].id
                    }
                  } else if (group.programs[i - 1]) {
                    item.battleProgram = group.programs[i - 1].id
                  }
                  if (!item.battleProgram) {
                    item.disabled = true
                  }
                }
                data.programsMap[item.id] = item
                data.programs.push(item)
                return item
              }).sort((a, b) => a.sort > b.sort ? 1 : -1)
            }
            data.programGroupsMap[group.id] = group
            return group
          })
        }
        if (payload.items) {
          payload.items.forEach(item => {
            data.itemsMap[item.id] = item
          })
        }
        if (payload.testPrograms) {
          data.testPrograms = payload.testPrograms.map((item, i) => {
            if (isBattle) {
              if (i % 2 === 0) {
                if (payload.testPrograms[i + 1]) {
                  item.battleProgram = payload.testPrograms[i + 1].id
                }
              } else if (payload.testPrograms[i - 1]) {
                item.battleProgram = payload.testPrograms[i - 1].id
              }
              if (!item.battleProgram) {
                item.disabled = true
              }
            }
            data.programsMap[item.id] = item
            return item
          }).sort((a, b) => a.sort > b.sort ? 1 : -1)
          data.programs.push(...data.testPrograms)
        }
        return state.set('controlCurrentMatch', fromJS(data))
      } else {
        return state.set('controlCurrentMatch', fromJS({}))
      }
    },
    [actions.controlSetCurrentMatchLinkData](state, { payload }) {
      const controlCurrentMatch = state.get('controlCurrentMatch').toJS()
      return state.set('controlCurrentMatch', fromJS({ ...controlCurrentMatch, linkData: payload }))
    },
    [actions.controlSetSignInData](state, { payload }) {
      if (payload) {
        return state.set('controlSignInData', fromJS(payload.judges))
      } else {
        return state.set('controlSignInData', fromJS({}))
      }
    },
  },
  fromJS({
    controlCurrentMatch: fromJS({}), // 当前比赛
    controlSignInData: fromJS([])
  })
)

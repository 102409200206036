import axios from './instance'

function getToken(competitionId) {
    return localStorage.getItem(`score-${competitionId}-token`)
}

// 控制台当前比赛信息 
export async function controlGetCurrentMatch(competitionId) {
    console.log(`进入控制台  competitionId=${competitionId}`)
    const token = getToken(competitionId)
    return axios.patch(
        `/api/competition/${competitionId}/console`,
        {},
        { headers: { Authorization: token } }
    )
}
// 签到 
export async function controlSignIn(competitionId, data) {
    console.log(`签到  competitionId=${competitionId} signInShowType=${data.signInShowType}`)
    const token = getToken(competitionId)
    return axios.post(
        `/api/competition/${competitionId}/console/sign-in?signInShowType=${data.signInShowType}`,
        {},
        {
            headers: { Authorization: token }
        }
    )
}
// 切换项目组
export async function controlProjectGroupSwitch(competitionId, programGroupId) {
    console.log(`切换项目组 competitionId=${competitionId} programGroupId=${programGroupId}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/switch/program/group/${programGroupId}`,
        { headers: { Authorization: token } }
    )
}
// 宣讲
export async function controlProjectOnSpeech(competitionId, programId, itemId, test) {
    console.log(`宣讲 competitionId=${competitionId} programId=${programId} itemId=${itemId} test=${test}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/program/${programId}/item/${itemId}/speech`,
        {
            headers: { Authorization: token },
            params: { test }
        }
    )
}
// 打分
export async function controlScoreStart(competitionId, programId, itemId, test) {
    console.log(`打分 competitionId=${competitionId} programId=${programId} itemId=${itemId} test=${test}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/program/${programId}/item/${itemId}/score`,
        {
            headers: { Authorization: token },
            params: { test }
        }
    )
}
// 亮分
export async function controlScoreEnd(competitionId, programId, itemId, test) {
    console.log(`亮分 competitionId=${competitionId} programId=${programId} itemId=${itemId} test=${test}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/program/${programId}/item/${itemId}/show/score`,
        {
            headers: { Authorization: token },
            params: { test }
        }
    )
}
// 成绩
export async function controlProjectShowResult(competitionId, programId, itemId, test) {
    console.log(`成绩 competitionId=${competitionId} programId=${programId} itemId=${itemId} test=${test}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/program/${programId}/item/${itemId}/show/result`,
        {
            headers: { Authorization: token },
            params: { test }
        }
    )
}
// 控制台 项目列表 
export async function controlGetProgramList(competitionId) {
    console.log(`控制台 项目列表  competitionId=${competitionId}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/program/list`,
        { headers: { Authorization: token } }
    )
}
//  测试项目
export async function controlGetTestList(competitionId) {
    console.log(`控制台 测试项目列表  competitionId=${competitionId}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/test`,
        { headers: { Authorization: token } }
    )
}
// 开屏
export async function controlShowMatchPage(competitionId) {
    console.log(`开屏 competitionId=${competitionId}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/init`,
        { headers: { Authorization: token } }
    )
}

// 排名展示样式切换 
export async function controlChangeRankShowType(competitionId, rankShowType) {
    console.log(`控制台 排名展示样式切换  competitionId=${competitionId} rankShowType=${rankShowType}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/rank?rankShowType=${rankShowType}`,
        { headers: { Authorization: token } }
    )
}

// 最终排名展示翻页
export async function controlPageTo(competitionId, itemId, pageNo, rankShowType) {
    console.log(`最终排名展示翻页 competitionId=${competitionId} itemId=${itemId} pageNo=${pageNo} rankShowType=${rankShowType}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/rank/turn/page`,
        {
            headers: { Authorization: token },
            params: { itemId, rankShowType, pageNo }
        }
    )
}

// 签到列表
export async function controlGetSignInJudges(competitionId) {
    console.log(`签到列表 competitionId=${competitionId}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/sign-in`,
        { headers: { Authorization: token } }
    )
}

// 对决开始
export async function controlBattleStart(competitionId, programAId, programBId, test) {
    console.log(`对决开始 competitionId=${competitionId} programAId=${programAId} programBId=${programBId} test=${test}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/program/${programAId}/${programBId}/battle`,
        {
            headers: { Authorization: token },
            params: { test }
        }
    )
}
// 对决暂停 
export async function controlBattlePause(competitionId, test) {
    console.log(`对决暂停 competitionId=${competitionId}}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/pause`,
        {
            headers: { Authorization: token },
            params: { test }
        }
    )
}
// 对决继续
export async function controlBattleResume(competitionId, test) {
    console.log(`对决继续 competitionId=${competitionId}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/resume`,
        {
            headers: { Authorization: token },
            params: { test }
        }
    )
}
// 对决违规
export async function controlBattleFoul(competitionId, programId, foulId) {
    console.log(`对决违规 competitionId=${competitionId} programId=${programId} foulId=${foulId}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/program/${programId}/foul`,
        {
            headers: { Authorization: token },
            params: { foulId }
        }
    )
}
// 对决打分
export async function controlBattlePoint(competitionId, programId, point) {
    console.log(`对决打分 competitionId=${competitionId} programId=${programId} point=${point}`)
    const token = getToken(competitionId)
    return axios.post(
        `/api/competition/${competitionId}/console/program/${programId}/point?point=${point}`,
        {},
        { headers: { Authorization: token } }
    )
}
// 对决判胜
export async function controlBattleDetermine(competitionId, programAId, programBId, test) {
    console.log(`对决判胜 competitionId=${competitionId} programAId=${programAId} programBId =${programBId}`)
    const token = getToken(competitionId)
    return axios.get(
        `/api/competition/${competitionId}/console/program/${programAId}/${programBId}/determine`,
        {
            headers: { Authorization: token },
            params: { test }
        }
    )
}


import React from 'react'
import styled from 'styled-components'
import newControlBg from 'common/img/new-control-bg.png'
import newControlBgWebp from 'common/img/new-control-bg.webp'
import borderBg from 'common/img/control-border1.png'
import { vw, vh } from 'common/utils'

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    padding: ${vh(30)} ${vw(116)} ${vh(114)};
    background-image: url(${newControlBgWebp}), url(${newControlBg});
`

const Title = styled.div`
    height: ${vh(84)};
    line-height: ${vh(84)};
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    margin-left: ${vw(130)};
`

const Content = styled.div`
    width: ${vw(1499)};
    //width: calc(100vw - ${vw(116 * 2)} - ${vw(94 * 2)});
    height: calc(100vh - ${vh(228)});
    background-color: rgba(0, 168, 255, .1);//rgba(47, 134, 226, .1);
    display: flex;
    // background-image: url(${borderBg}), url(${borderBg});
    // background-repeat: no-repeat, no-repeat;
    // background-position: left, right;
    // background-size: contain, contain;
    margin-left: ${vw(94)};
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        width: ${vw(94)};
        height: calc(100% + 20px);
        background-image: url(${borderBg});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -10px;
    }

    &::before {
        left: -${vw(94)};
    }

    &::after {
        transform: rotateY(180deg);
        right: -${vw(94)};
    }
`

const Left = styled.div`

`

const BgBox = ({
    title = '',
    children
}) => {
    return (
        <Wrapper>
            <Title>{title}</Title>
            <Content>
                {children}
            </Content>
        </Wrapper>
    )
}

export default BgBox
import { createActions } from 'redux-actions'
import api from 'api'
import { getPageFromUI } from 'common/utils'

const actions = createActions(
  {
    SETTING_GET_MATCH_LIST: (fields = {}, pagination = { page: 1, size: 10 }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getCompetitionList({ ...fields, ...getPageFromUI(pagination) })
        dispatch(actions.settingSetMatchList(res.data))
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_ADD_MATCH: (payload, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.addCompetition(payload)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_MATCH_DETAIL: (payload, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getCompetitionDetail(payload)
        dispatch(actions.settingSetMatchDetail(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_MATCH_SCORE_DETAIL: (payload, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getCompetitionScoreDetail(payload)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_MATCH: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateCompetition(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_MATCH_SCORE: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateCompetitionScore(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_DELETE_MATCH: (payload, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.deleteCompetition(payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_JUDGE_LIST: ({ id, pagination = { page: 1, size: 10 }, fields = {} }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const _fields = {}
        for (const key in fields) {
          if (fields[key] !== undefined && fields[key] !== null) {
            _fields[key] = fields[key]
          }
        }
        const res = await api.setting.getJudgeList(id, { ..._fields, ...getPageFromUI(pagination) })
        dispatch(actions.settingSetJudgeList(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_JUDGE_GROUP_LIST: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getJudgeGroupList(id)
        dispatch(actions.settingSetJudgeGroupList(res.data))
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_MATCH_MANAGEABLE_DATA: (successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getCompetitionManageable()
        dispatch(actions.settingSetManageableCompetitions(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_JUDGE_APPROVED_DATA: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getJudgeApproved(id)
        dispatch(actions.settingSetJudgeApprovedData(res.data))
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_IMPORT_JUDGE_FROM_BEFORE: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.importJudgeFromBefore(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_IMPORT_JUDGE_FROM_EXCEL: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.importJudgeFromExcel(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_ADD_JUDGE: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.addJudge(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_AUDIT_COMMON_JUDGE: ({ id: competitionId, judgeId, approve, groupId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.auditCommonJudge(competitionId, judgeId, { approve, groupId })
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_JUDGE_CHECK: (data, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.judgePhoneCheck(data)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_DELETE_JUDGE: ({ competitionId, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.deleteJudge(competitionId, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_JUDGE: ({ competitionId, judgeId, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateJudge(competitionId, judgeId, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_JUDGE_GROUP: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateJudgeGroup(id, { judgeGroups: payload })
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_JUDGE_JOIN_GROUP: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.judgeJoinGroup(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_PROJECT_LIST: (payload, successCB, failCB) => async (dispatch, getState) => {
      try {
        const result = await api.setting.getPojectList(payload)
        dispatch(actions.settingSetProjectList(result.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_IMPORT_PROJECT_FROM_EXCEL: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.importProjectFromExcel(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_DELETE_PROJECT: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.deleteProject(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_OTHER_PROJECT_LIST: (payload, successCB, failCB) => async (dispatch, getState) => {
      try {
        const result = await api.setting.getPojectList(payload)
        dispatch(actions.settingSetOtherProjectList(result.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_IMPORT_PROJECT_FROM_BEFORE: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.importProjectFromBefore(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_ADD_PROJECT: ({ competitionId, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.addProject(competitionId, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_PROJECT: ({ competitionId, id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateProject(competitionId, id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_PROJECT_SORT: ({ competitionId, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateProjectSort(competitionId, { programSorts: payload })
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_PROJECT_GROUP: ({ competitionId, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateProjectGroup(competitionId, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_JUDGE_SORT_UP: ({ competitionId, id }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.judgeNumberUp(competitionId, id)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_JUDGE_SORT_DOWN: ({ competitionId, id }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.judgeNumberDown(competitionId, id)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_JUDGE_REORDER: ({ competitionId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.judgeReorder(competitionId)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_MATCH_OFFICIAL: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.competitionOfficial(id)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_STATISTICS_DATA: ({ id }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getStatisticsData(id)
        dispatch(actions.settingSetStatisticsData(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_STATISTICS_DETAIL: ({ id, programId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getStatisticsDetail(id, programId)
        dispatch(actions.settingSetStatisticsDetail(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_RESET_STATISTICS_DATA: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.resetStatisticsData(id)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_EXPORT_STATISTICS_DATA: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        const response = await api.setting.exportStatisticsData(id)
        var blob = new Blob([response.data])
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        const fileName = response.headers['content-disposition']
          .split(';')
          .filter((t) => t.startsWith('fileName='))
          .join('')
          .substr(9)
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_EXPORT_SCORE: ({ id, data }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const response = await api.setting.exportScore(id, data)
        var blob = new Blob([response.data])
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        const fileName = response.headers['content-disposition']
          .split(';')
          .filter((t) => t.startsWith('fileName='))
          .join('')
          .substr(9)
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_COPY_MATCH: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.copyCompetition(id)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_PROMOTION_MATCH: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.promotionCompetition(id)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_MATCH_STATUS: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getMatchStatus(id)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_MATCH_NAME: ({ id, data }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateMatchName(id, data)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_USER_POINT: (successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getUserPoint()
        dispatch(actions.settingSetUserPoint(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_POINT_RECHARGE_DATA: (successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getPointRecharge()
        const data = res.data.rechargeRecords
        dispatch(actions.settingSetPointRecordData({ data, type: 'RECHARGE' }))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_POINT_USE_DATA: (successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getPointUse()
        const data = res.data.consumeRecord
        dispatch(actions.settingSetPointRecordData({ data, type: 'USE' }))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_USER_INFO: (successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getCurrentUser()
        dispatch(actions.settingSetUserInfo(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_USER_PASSWORD: (payload, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateUserPassword(payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_DING_ADDRESS_BOOK: ({ corpid, deptId, parentId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getAddressBook(corpid, deptId)
        dispatch(actions.settingSetDingAddressBook({ parentId, deptId, data: res.data }))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_IMPORT_JUDGE_FROM_ADDRESS_BOOK: ({ id, payload }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.importJudgeFromAddressBook(id, payload)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_EXCHANGE_POINT: (code, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.pointVoucher(code)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_POINT_PLAN: (successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getPointPlan()
        dispatch(actions.settingSetPointPlan(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_POINT_PLAN_QRCODE: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getPointPlanQRCode(id)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_REFUND: (tradeNo, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.refundAlipay(tradeNo)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_ALIPAY_WEB: (tradeNo, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.alipayWeb(tradeNo)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_SUB_USER: (successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getSubUser()
        dispatch(actions.settingSetSubUser(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_ADD_SUB_USER: (data, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.addSubUser(data)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_DELETE_SUB_USER: (data, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.deleteSubUser(data)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_ADD_SUB_USER_FROM_DING: (data, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.addSubUserFromDing(data)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_UPDATE_SUB_USER: ({ id, data }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.updateSubUser(id, data)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_DETACH_MAIN_USER: (successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.detachMainUser()
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_APPLY_INVOICE: ({ tradeNo, data }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.setting.applyInvoice(tradeNo, data)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    SETTING_GET_INVOICE_INFO: (data, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.setting.getInvoiceTitle(data)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
  },
  'SETTING_SET_MATCH_LIST',
  'SETTING_SET_MATCH_DETAIL',
  'SETTING_SET_JUDGE_LIST',
  'SETTING_SET_JUDGE_GROUP_LIST',
  'SETTING_SET_MANAGEABLE_COMPETITIONS',
  'SETTING_SET_JUDGE_APPROVED_DATA',
  'SETTING_SET_PROJECT_LIST',
  'SETTING_SET_OTHER_PROJECT_LIST',
  'SETTING_SET_STATISTICS_DATA',
  'SETTING_SET_STATISTICS_DETAIL',
  'SETTING_SET_PROJECT_LIST_BY_SORT',
  'SETTING_SET_POINT_RECORD_DATA',
  'SETTING_SET_USER_POINT',
  'SETTING_SET_USER_INFO',
  'SETTING_SET_DING_ADDRESS_BOOK',
  'SETTING_SET_ENVIRONMENT',
  'SETTING_SET_POINT_PLAN',
  'SETTING_SET_SUB_USER'
)

export default { ...actions }

import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'

const SubItemName = styled.span`
    font-size: 14px;
    color: #CCCCCC;
    margin-right: 12px;
`


const ISelect = styled(Select)`
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #4E8BB9 !important;
        background-color: #011335;
    }

    &.ant-select {
        color: #27A3FF;
    }

    .ant-select-arrow {
        color: #558BD1;
    }
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #4E8BB9 !important;
    }
`

const ItemSelect = ({
    options,
    value,
    onChange
}) => {
    return (
        <div>
            <SubItemName>选择分项:</SubItemName>
            <ISelect
                style={{ width: 226 }}
                value={value}
                placeholder={'请选择分项名称'}
                dropdownClassName={'sub-item-select'}
                onChange={onChange}
            >
                {options.map(({ id, itemName }) => (
                    <ISelect.Option key={id}>
                        {itemName}
                    </ISelect.Option>
                ))}
            </ISelect>
        </div>
    )
}

export default ItemSelect

import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { immutableGet, rawJs } from 'common/selectorTools'
import { actions, selectors } from 'ducks/control'
import { message, Spin } from 'antd'
import { vw, vh } from 'common/utils'
import CommonRect from './CommonRect'
import vs from 'common/img/vs-control.png'
import ToolsBar from './ToolsBar/index'

const Wrapper = styled.div`
    // width: ${vw(1020)};
    height: ${vh(579)};
    display: flex;
    flex-direction: column;
    background: rgba(5,36,75,0.75);
    border: 1px solid #0F72CD;
    overflow-y: auto;
`

const Desc = styled.div`
    font-size: ${vw(14)};
    text-align: center;
    // font-family: PingFang SC;
    font-weight: 400;
    color: #C8E5FF;
    margin: 18px 0 26px 0;
    // position: absolute;
    // top: ${vh(24)};
    // left: 50%;
    // transform: translate(-50%, 0);
`

const NameText = styled.div`
    color: #C8E5FF;
    font-size: 16px;
    margin: 14px 0 0 18px;
`

const SkewRect = styled.div`
    width: 128px;
    background: ${props => props.color};
    height: 22px;
    transform: skewX(-30deg);
    color: #ffffff;
    margin: 0 4px;
`

const SkewText = styled.div`
    height: 22px;
    line-height: 22px;
    color: #ffffff;
    transform: skewX(30deg);
    margin-left: 20px;
`

const Vs = styled.div`
    width: 47px;
    height: 29px;
    background: url(${vs}) no-repeat;
    background-size: contain;
    background-position: center center;
`

const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: ${vh(12)};
    flex: 1;
    align-items: center;
`

const PageItem = styled.div`
    width: ${vw(68)};
    height: ${vw(68)};
    margin: ${vh(10)} ${vw(10)};
    background: ${props => props.active ? '#072844' : '#133B5D'};
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }

    &:before {
        width: ${vw(60)};
        height: ${vw(60)};
        background: linear-gradient(90deg, ${props => props.active ? '#0F72CD, #5BBAFB' : '#FDFEFE, #B2B2B2'}); 
        
        z-index: 1;
    }

    &:after {
        width: ${vw(50)};
        height: ${vw(50)};
        background: linear-gradient(180deg, ${props => props.active ? '#0F72CD, #5BBAFB' : '#FDFEFE, #B2B2B2'});
        z-index: 2;
    }

    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: ${vw(30)};
        font-weight: 400;
        color: ${props => props.active ? '#FCFDFD' : '#767676'} ;
        z-index: 3;

        ${props => !props.active && css`
            background: linear-gradient(0deg, #4F4F4F 0%, #7E7E7E 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        `}
    }

`

const RankItem = styled.div`
    flex: 1;
    padding: ${vh(66)} ${vw(45)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

class Rank extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            subItemList: [], // 分项列表
            activeItemId: null, // 当前分项
            curGroup: null,
            rankShowType: '',
            rankInfo: [
                { name: '默认排名展示', type: 'default' },
                { name: 'PK排名展示', type: 'pk' }
            ],
            rankColors: ['#5BB8FB', '#3476A5', '#1B4263']
        }
    }

    componentDidMount() {
        this.fetchGroupData()
    }

    fetchGroupData = () => {
        const {
            matchInfo: {
                items,
                activeProgramGroupId,
                programGroupsMap,
                setting: { enableItem, enableScoreItemCombine },
                linkData
            } } = this.props
        const matchLinkData = linkData || {}
        const { rankShowType, rankPageSize, rankPageNo, rankItemId, rankTotalSize } = matchLinkData
        const subItemList = [...items]
        let hasAllItem = false
        if (enableItem && !enableScoreItemCombine && subItemList.length > 1) {
            subItemList.unshift({ id: 'allItem', itemName: '全部', proportion: 1 })
            hasAllItem = true
        }
        this.setState({
            programGroupsMap,
            subItemList: [
                ...subItemList
            ],
            curGroup: programGroupsMap[activeProgramGroupId] ? programGroupsMap[activeProgramGroupId] : null,
            activeProgramGroupId,
            enableScoreItemCombine,
            //
            rankShowType,
            pageNo: rankPageNo,
            rankPageSize: rankShowType === 'pk' ? 6 : rankPageSize,
            activeItemId: rankItemId ? `${rankItemId}` : (hasAllItem ? 'allItem' : `${items[0].id}`),
            showTotal: rankShowType ? true : false,
            rankTotalSize,
            loaded: true
        })
        if (rankShowType) {
            const rankPageNo = linkData.rankPageNo ? linkData.rankPageNo : 0
            this.handleRankShowTypeOnChange(rankShowType, () => {
                this.handlePageNoOnChange(rankPageNo, rankShowType)
            })
        }
    }

    handleSubItemOnChange = (activeItemId) => {
        this.setState({
            activeItemId,
            rankShowType: '',
            pageNo: null
        })
    }

    handleRankShowTypeOnChange = (rankShowType, cb) => {
        const { competitionId } = this.props
        this.setState({ loading: true })
        this.props.controlChangeRankShowType(
            { competitionId, rankShowType },
            (result) => {
                console.log(result)
                const { matchInfo: {
                    setting: { enableItem },
                } } = this.props
                const { rankPageSize, rankItemId, programSize, itemSize } = result
                let rankTotalSize = programSize
                if (enableItem && rankItemId !== null && rankItemId !== undefined && rankItemId !== 'allItem') {
                    const curItem = itemSize.find(item => item.itemId === rankItemId)
                    if (curItem) {
                        rankTotalSize = curItem.itemSize
                    }
                }
                const commonState = {
                    rankShowType,
                    pageNo: null,
                    loading: false,
                    showTotal: true,
                    linkData: result,
                    rankTotalSize,
                    rankLoaded: true
                }
                if (rankShowType === 'pk') {
                    this.setState({
                        rankPageSize: 6,
                        ...commonState
                    })
                    cb && cb()
                } else {
                    if (result && result.rankPageSize) {
                        this.setState({
                            rankPageSize,
                            ...commonState
                        })
                        cb && cb()
                    }
                }
            },
            () => {
                this.setState({
                    loading: false
                })
                message.error('切换失败')
            }
        )
    }

    handleGroupOnChange = (data) => {
        const { competitionId } = this.props
        this.props.controlProjectGroupSwitch(
            { competitionId, programGroupId: data.id },
            () => {
                const { programGroupsMap } = this.state
                this.setState({
                    curGroup: programGroupsMap[data.id],
                    pageNo: null,
                    showTotal: false,
                    rankShowType: null
                })
            },
            () => {
                message.error('切换项目组失败')
            }
        )
    }

    handlePageNoOnChange = (pageNo, rankShowType) => {
        const { competitionId } = this.props
        const { activeItemId: itemId, subItemList } = this.state
        this.setState({ loading: true })
        this.props.controlResultPageTo(
            { competitionId, itemId: itemId ? (itemId === 'allItem' ? undefined : itemId) : subItemList[0].id, pageNo, rankShowType },
            () => {
                this.setState({
                    pageNo,
                    loading: false,
                    rankShowType
                })
            },
            () => {
                this.setState({
                    loading: false
                })
                message.error('操作失败')
            }
        )
    }

    render() {
        const { showTotal, rankInfo, rankColors, rankPageSize, loaded, loading, activeItemId, curGroup, subItemList, linkData = {}, rankShowType } = this.state
        const {
            matchInfo: { programGroups, setting: { enableItem, enableScoreItemCombine } },
        } = this.props
        const { programSize = 0, itemSize = [] } = linkData
        let rankTotalSize = programSize
        if (enableItem && activeItemId !== null && activeItemId !== undefined && activeItemId !== 'allItem') {
            const curItem = itemSize && itemSize.find(item => item.itemId == activeItemId)
            if (curItem) {
                rankTotalSize = curItem.itemSize
            }
        }
        const pageSize = rankShowType === 'pk' ? 6 : rankPageSize
        const pageLen = rankTotalSize ? Math.ceil(rankTotalSize / pageSize) : 0

        return (
            <div style={{ marginTop: vh(34), width: '100%' }}>
                <Spin spinning={loading}>
                    <ToolsBar
                        loaded={loaded}
                        groupReadOnly={!(programGroups && programGroups.length > 1)}
                        groupDataSource={programGroups}
                        groupOnOk={this.handleGroupOnChange}
                        groupInitialValue={curGroup ? curGroup : null}
                        itemOptions={subItemList}
                        itemValue={activeItemId}
                        itemOnChange={this.handleSubItemOnChange}
                        itemVisible={enableItem && !enableScoreItemCombine}
                    />
                    <Wrapper>
                        {/* {showTotal && <Desc>已有排名的项目总计 {total} 个，展示分 {pageLen} 页，每页 {rankPageSize} 个。</Desc>} */}
                        <div
                            style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 26 }}
                        >
                            {rankInfo.map(({ name, type }, i) => {
                                return (
                                    <CommonRect
                                        key={type}
                                        style={{ marginRight: i === 0 ? vw(100) : 0 }}
                                        active={this.state.rankShowType === type}
                                    >
                                        <div
                                            style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
                                            onClick={() => {
                                                if (this.state.rankLoaded) {
                                                    this.handlePageNoOnChange(0, type)
                                                } else {
                                                    this.handleRankShowTypeOnChange(type, () => {
                                                        this.handlePageNoOnChange(0, type)
                                                    })
                                                }
                                            }}
                                        >
                                            <NameText>{name}</NameText>
                                            {type === 'default' && (
                                                <RankItem>
                                                    {rankColors.map((color, i) => (
                                                        <div
                                                            key={color}
                                                            style={{
                                                                background: color,
                                                                height: 24,
                                                                lineHeight: '24px',
                                                                color: '#ffffff',
                                                                paddingLeft: 10
                                                            }}
                                                        >
                                                            TOP {i + 1}
                                                        </div>
                                                    ))}
                                                </RankItem>
                                            )}
                                            {type === 'pk' && (
                                                <RankItem>
                                                    {rankColors.map((color, i) => (
                                                        <div key={color} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <SkewRect
                                                                color={color}
                                                            >
                                                                {i === 0 && <SkewText>TOP 1</SkewText>}
                                                            </SkewRect>
                                                            {i === 1 && <Vs />}
                                                            <SkewRect
                                                                color={color}
                                                            >
                                                                {i === 0 && <SkewText>TOP 2</SkewText>}
                                                            </SkewRect>
                                                        </div>
                                                    ))}
                                                </RankItem>
                                            )}
                                        </div>
                                    </CommonRect>
                                )
                            })}
                        </div>
                        {showTotal && rankShowType && (
                            <PageWrapper>
                                {pageLen && new Array(pageLen)
                                    .fill('')
                                    .map((item, i) => (
                                        <PageItem
                                            key={i}
                                            active={this.state.pageNo === i}
                                            onClick={this.handlePageNoOnChange.bind(this, i, rankShowType)}
                                        >
                                            <div>{i + 1}</div>
                                        </PageItem>
                                    ))}
                            </PageWrapper>
                        )}
                    </Wrapper>
                </Spin>
            </div >
        )
    }
}

const { controlSelector } = selectors

const mapStateToProps = createStructuredSelector({
    matchInfo: rawJs(immutableGet(controlSelector, 'controlCurrentMatch'))
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(Rank)

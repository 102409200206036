import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { vw, vh } from 'common/utils'
import { Spin } from 'antd'

const SignInWrapper = styled.div`
    // height: ${vh(480)};
    height: ${vh(600)};
    background: rgba(5,36,75,0.75);
    // background: #01142C;
    border: 1px solid #0F72CD;
    position: relative;

    
    *::-webkit-scrollbar-track-piece {
        border-right: none !important;
        border-bottom: none !important;
        border-left: none !important;
    }

    .spanning-box {
        height: 100%;
        .ant-spin-container {
            height: 100%;
        }
    }
`

const TemplateWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0 ${vw(182)};
`

const Template = styled.div`
    width: ${vw(390)};
    height: ${vh(320)};
    // height: ${vh(500)};
    // flex: 1;
    // background: #012D51;
    cursor: pointer;
    border: ${vw(1)} solid #014C93;
    display: flex;
    flex-direction: column;

    &.active {
        background: #051838;
        border-color: #5BB8FB;
        box-shadow: 0px 0px 14px 0px #5bb8fb;
    }

    &:hover {
        border-color: #5BB8FB;
        box-shadow: 0px 0px 14px 0px #5bb8fb;
    }  
`

const TemplateTitle = styled.div`
    min-height: ${vh(34)};
    margin-left: ${vw(17)};
    color: #C8E5FF;
    font-size: ${vh(16)};
    display: flex;
    align-items: flex-end;
`

const TemplateContent = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 0 ${vw(50)};
`

const Circle = styled.div`
    position: relative;
    width: ${vh(162)};
    height: ${vh(162)};
    border-radius: 50%;
    background-image: linear-gradient(90deg, #5BB8FB 50%, transparent 50%, transparent) , linear-gradient(0deg, #5BB8FB 50%, #1B4263 50%, #1B4263);
    // line-height: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin: 0 auto;

    div {
        z-index: 3;
        color: #FFFFFF;
        font-size: ${vh(18)};
    }

    &:after {
        content: '';
        display: block;
        width: ${vh(144)};
        height: ${vh(144)};
        background: #012D51;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
`

const Rectangle = styled.div`
    width: ${vw(50)};
    height: ${vh(36)};
    margin: ${vh(12)} ${vw(10)};
    position: relative;
    border-radius: ${vw(4)};
    bottom: 0;
    z-index: 0;
    border: ${vw(3)} solid ${props => props.isSigned ? '#5BB8FB' : '#1B4263'};
    &:before, &:after {
        content: '';
        display: block;
        width: ${vw(5)};
        height: ${vh(11)};
        background: ${props => props.isSigned ? '#5BB8FB' : '#1B4263'};
        border-radius: ${vw(3)};
        position: absolute;
        top: -${vh(7)};
        z-index: 1;
        background: ${props => props.isSigned ? '#5BB8FB' : '#1B4263'};
    }

    &:before {
        left: ${vw(13)};
    }

    &:after {
        right: ${vw(13)};
    }
`

const Rectangle2 = styled.div`
    min-width: ${vw(82)};
    height: ${vh(72)};
    padding: 0 ${vw(16)};
    margin: 0 ${vw(10)} ${vh(50)} ${vw(10)};
    border: ${vw(1)} solid ${props => props.isSigned ? '#5BB8FB' : '#133B5D'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: ${vh(14)};
    position: relative;
    border-radius: ${vw(4)};

    div:nth-of-type(2) {
        color: ${props => props.isSigned ? '#FFFFFF' : '#1E496D'};
    }

    div:nth-of-type(3) {
        color: ${props => props.isSigned ? '#B0DDFF' : '#1E496D'};
    }
`

const BtnWrapper = styled.div`
    // position: absolute;
    // top: ${vh(24)};
    // right: ${vw(32)};
    margin-top: ${vh(55)};
    margin-bottom: ${vh(20)};
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const Btn = styled.div`
    color: #C8E5FF;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    i {
        margin-right: ${vw(10)};
    }
`

const ListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    // margin-top: 52px;
    height: calc(100% - 52px);
    overflow-y: auto;
    padding: ${vh(8)} ${vw(28)} 0 ${vw(30)};
    align-content: flex-start;
`

const Line = styled.div`
    width: ${vw(32)};
    height: ${vh(12)};
    position: absolute;
    top: -${vh(5)};

    &:before, &:after {
        content: '';
        display: block;
        width: ${vw(6)};
        height: ${vh(12)};
        background: ${props => props.isSigned ? '#5BB8FB' : '#133B5D'};
        position: absolute;
        border-radius: ${vw(3)};
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }
`

class SignInPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isList: false,
            signTypes: [
                { type: 'total' },
                {
                    type: 'tile',
                    data: new Array(12).fill('').map((item, inx) => ({ inx, isSigned: [0, 3, 6, 9].includes(inx) }))
                }
            ]
        }
    }

    handleSetIsList = () => {
        const { isList } = this.state
        this.setState({
            isList: !isList
        }, () => {
            if (this.state.isList) {
                this.handleRefresh()
            }
        })
    }

    handleTemplateOnChange = (current) => {
        this.props.onChange(current)
    }

    handleRefresh = () => {
        this.props.getSignInData()
    }

    render() {
        const { spinning, title = '', data, signInShowType } = this.props
        const { isList, signTypes } = this.state

        return (
            <div style={{ width: vw(1020) }}>
                <BtnWrapper>
                    <Btn onClick={this.handleRefresh} style={{ marginRight: vw(20), visibility: isList ? 'visible' : 'hidden' }}>
                        <i className={'iconfont icon-htmal5icon23'} />
                        刷新
                    </Btn>
                    <Btn onClick={this.handleSetIsList}>
                        <i
                            className={'iconfont icon-qiehuan1'}
                            style={{ fontSize: vh(18) }}
                        />
                        {isList ? '大屏展示' : '列表展示'}
                    </Btn>
                </BtnWrapper>
                <SignInWrapper>
                    <Spin spinning={spinning} wrapperClassName={'spanning-box'}>
                        {isList ? (
                            <div style={{ height: '100%', paddingTop: 52, overflowY: 'hidden' }}>
                                <ListWrapper>
                                    {data
                                        .sort((a, b) => a.number > b.number ? 1 : -1)
                                        .map(item => (
                                            <Rectangle2 isSigned={item.signIn} key={item.number}>
                                                <Line isSigned={item.signIn} />
                                                <div>
                                                    {`${item.number} ${item.name}`}
                                                </div>
                                                <div>
                                                    {item.signIn ? '已签到' : '未签到'}
                                                </div>
                                            </Rectangle2>
                                        ))}
                                </ListWrapper>
                            </div>
                        ) : (
                            <TemplateWrapper>
                                {signTypes.map(({ type, data }, i) => (
                                    <Template
                                        key={i}
                                        onClick={this.handleTemplateOnChange.bind(this, type)}
                                        className={signInShowType === type ? 'active' : ''}
                                        style={{ marginRight: i === 0 ? vw(100) : 0 }}
                                    >
                                        <TemplateTitle>{title}</TemplateTitle>
                                        {type === 'total' && (
                                            <TemplateContent style={{ alignItems: 'center' }}>
                                                <Circle>
                                                    <div>已签到</div>
                                                    <div>未签到</div>
                                                </Circle>
                                            </TemplateContent>
                                        )}
                                        {type === 'tile' && (
                                            <TemplateContent style={{ alignContent: 'center' }}>
                                                {data.map(({ inx, isSigned }) => (
                                                    <Rectangle key={inx} isSigned={isSigned} />
                                                ))}
                                            </TemplateContent>
                                        )}
                                    </Template>
                                ))}
                            </TemplateWrapper>
                        )}
                    </Spin>
                </SignInWrapper>
            </div>
        )
    }
}

export default SignInPage
import { handleActions } from 'redux-actions'
import actions from './webActions'
import { fromJS } from 'immutable'

export default handleActions(
    {
        [actions.webSetCurrentMatchInfo](state, { payload }) {
            if (payload) {
                const data = { ...payload }
                data.programsMap = {}
                data.programs = []
                data.programGroupsMap = {}
                data.itemsMap = {}
                payload.programGroups && payload.programGroups.forEach(group => {
                    data.programGroupsMap[group.id] = group
                    group.programs && group.programs.forEach((item) => {
                        data.programsMap[item.id] = item
                        data.programs.push(item)
                    })
                })
                payload.items && payload.items.forEach(item => {
                    data.itemsMap[item.id] = item
                })
                if (payload.testPrograms) {
                    data.programs.push(...payload.testPrograms)
                    payload.testPrograms.forEach(item => {
                        data.programsMap[item.id] = item
                    })
                }
                return state.set('currentMatchInfo', fromJS(data))
            }
            return state.set('currentMatchInfo', fromJS({}))
        },
        [actions.webSetWsData](state, { payload }) {
            if (payload) {
                let data = { timestamp: new Date().getTime() }
                if (typeof payload === 'object') {
                    data = { ...data, ...payload }
                } else {
                    data.event = payload
                }
                return state.set('wsData', fromJS(data))
            }

            return state
        },
        [actions.webSetCurrentMatchLinkInfo](state, { payload }) {
            if (payload && payload.data) {
                return state.set('currentMatchLinkInfo', fromJS({ ...payload.data, timestamp: new Date().getTime() }))
            }
            return state.set('currentMatchLinkInfo', fromJS({}))
        }
    },
    fromJS({
        currentMatchInfo: fromJS({ setting: {} }), // 正在进行的比赛基础设置信息
        wsData: fromJS({}),  // websocket推送的信息
        currentMatchLinkInfo: fromJS({})  // 正在进行的比赛环节信息
    })
)
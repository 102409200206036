import React from 'react'
import styled from 'styled-components'
import { vw, vh } from 'common/utils'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${vh(68)};
    padding-top:  ${vh(52)};
`

const IframeWrapper = styled.div`
    width: 100%;
    // height: 220px;
    background: #000111;
    border: 2px solid rgba(112,176,255,0.75);
    overflow: hidden;
    position: relative;
    display: flex;
    
    #iframe {
        #root {
          transform: scale(0.2);
        }
        body {
          transform: scale(0.2);
        }
    }
`

const ProjectInfo = styled.div`
    min-height: 44px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 4px;
`

const Mask = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
`

const Pedestal = styled.div`
    width: 50px;
    height: 20px;
    background: #558DD3;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 102px;
        height: 10px;
        background: #558DD3;
        position: absolute;
        left: 50%;
        margin-left: -51px;
        bottom: 0;
        border-radius: 20px;
    }
`

export default ({
    src,
    current,
    screenUrl
}) => {
    const width = 390
    const height = 220

    return (
        <Wrapper style={{ width }}>
            <ProjectInfo>
                {current && current.map((item, i) => (
                    <>
                        {i === 1 && <div style={{ margin: '0 4px' }}>VS</div>}
                        <div>
                            <span style={{ marginRight: 16 }}>{item.sort}号</span>
                            <span>{item.programName}</span>
                        </div>
                    </>
                ))}
            </ProjectInfo>
            <IframeWrapper>
                <iframe
                    title={'control'}
                    id={'iframe'}
                    width={width}
                    height={height}
                    src={src}
                    className={'iframe'}
                    frameBorder={'no'}
                />
                {process.env.NODE_ENV === 'production' && (
                    <Mask
                        onClick={() => {
                            window.open(screenUrl)
                        }}
                    />
                )}
            </IframeWrapper>
            <Pedestal></Pedestal>
        </Wrapper>
    )
}
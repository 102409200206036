import { createActions } from 'redux-actions'
import api from 'api'

const actions = createActions(
  {
    SYS_EXTERNAL_LOGIN: (payload, successCb, failCb) => async (dispatch, getState) => {
      try {
        const res = await api.sys.externalLogin(payload)
        successCb && successCb(res)
      } catch (err) {
        failCb && failCb(err)
      }
    },
    SYS_INTERNAL_LOGIN: (payload, successCb, failCb) => async (dispatch, getState) => {
      try {
        const res = await api.sys.internalLogin(payload)
        successCb && successCb(res)
      } catch (err) {
        failCb && failCb(err)
      }
    },
    SYS_GET_DING_AUTH: ({ query, prefix }, successCb, failCb) => async (dispatch, getState) => {
      try {
        localStorage.setItem('score-token', '')
        const res = await api.sys.getDingAuth(query)
        const { data = {} } = res
        if (localStorage.getItem('score-token')) {
          localStorage.removeItem('score-token')
        }
        localStorage.setItem('score-token', `${prefix}-${data.token}`)
        // websocket token
        if (localStorage.getItem('score-websocket-token')) {
          localStorage.removeItem('score-websocket-token')
        }
        localStorage.setItem('score-websocket-token', data.token)
        successCb && successCb(data)
      } catch (err) {
        console.log(err)
        failCb && failCb(err)
      }
    },
    SYS_EXISTS_LOGIN_NAME: (loginName, successCb, failCb) => async (dispatch, getState) => {
      try {
        const res = await api.sys.existsLoginName(loginName)
        successCb && successCb(res.data)
      } catch (err) {
        failCb && failCb(err)
      }
    },
    SYS_REGISTER_USER: (payload, successCb, failCb) => async (dispatch, getState) => {
      try {
        await api.sys.registerUser(payload)
        successCb && successCb()
      } catch (err) {
        failCb && failCb(err)
      }
    },
    SYS_WEB_LOGIN_FROM_ACCOUNT: (payload, successCb, failCb) => async (dispatch, getState) => {
      try {
        const res = await api.sys.webLogin(payload)
        successCb && successCb(res.data)
      } catch (err) {
        console.log(err)
        failCb && failCb(err)
      }
    },
    SYS_GET_LOGIN_CODE_IMG: (successCb, failCb) => async (dispatch, getState) => {
      try {
        const res = await api.sys.getLoginCode()
        successCb && successCb(res.data)
      } catch (err) {
        console.log(err)
        failCb && failCb(err)
      }
    },
    SYS_WEB_LOGIN_FROM_DD: (payload, successCb, failCb) => async (dispatch, getState) => {
      try {
        const res = await api.sys.webDDLogin(payload)
        successCb && successCb(res.data)
      } catch (err) {
        console.log(err)
        failCb && failCb(err)
      }
    },
  }
)

export default { ...actions }

import React from 'react'
import styled from 'styled-components'
import { Table } from 'antd'
const theadColor = '#333333'
const theadFontSize = 14
const theadBackground = '#F5F5F5'
const commomBorderColor = '#D3D9E3'
const tbodyColor = '#666666'
const tbodyFontSize = 14

const CustomTable = styled(Table)`
    .ant-table-small .ant-table-thead .ant-table-cell {
        padding: 7px 8px 8px 8px;
        font-size: 12px;
    }

    .ant-table-selection-column {
        padding: 0;
    }

    .ant-table-thead {
        .ant-table-cell:not(.ant-table-selection-column) {
            color: ${theadColor};
            padding: 11px 16px;
            font-size: ${theadFontSize}px;
        }
    }

    .ant-table-thead > tr > th {
        background: ${theadBackground};
    }

    .ant-table-tbody {
        .ant-table-cell {
            color: ${tbodyColor};
            font-size: ${tbodyFontSize}px;
        }
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:not(:last-child) {
        border-right-color: ${theadBackground};
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
        border-right-color: ${commomBorderColor};
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th {
        border-color: ${commomBorderColor};
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th.ant-table-cell-scrollbar {
        left: -1px;
        box-shadow: none;
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:not(.ant-table-cell-scrollbar) {
        border-right-color: ${theadBackground};
    }

    .ant-table-body {
        &::-webkit-scrollbar-track-piece {
          border-right: 1px solid  ${commomBorderColor};
          border-bottom: 1px solid  ${commomBorderColor};
          border-left: none;
        }
    }
`

export default function (props) {
    return <CustomTable {...props} />
}
import { createSelector } from 'reselect'

// 通用的方法，用于经常需要将 immutable 对象转化为原生对象时避免每次生成新对象造成重复渲染
export const rawJs = (selector) => createSelector(selector, (state, props) => state.toJS())

// 用于将父亲和儿子两个 selector 连接起来
// 原因：
// 一般来说 selector 是接受 根state 作为输入，但有些 selector 写法会以自己分支为根
// 这是为了方便的兼容两种写法
export const chain = (parentSelector, childSelector) => {
  return (state, props) => childSelector(parentSelector(state, props))
}

// 有时候实在是太懒了，懒到不愿意写那么多的 selector
// 毕竟，只是只读访问而已，没必要走 selector 那一套
export const immutableGet = (selector, ...params) => {
  if (params.length <= 0) {
    return selector
  }

  return params.length > 1
    ? createSelector(selector, (state, props) => state.getIn(params))
    : createSelector(selector, (state, props) => state.get(params[0]))
}

import * as sys from './sys.api'
import * as setting from './setting.api'
import * as dd from './dd.api'
import * as control from './control/api'

export default {
  sys,
  setting,
  control,
  dd
}

import React from 'react'
import styled from 'styled-components'
import { vw, vh } from 'common/utils'
import flowBg from 'common/img/flow-active-bg.png'

const OuterBox = styled.div`
    // width: ${vw(114)};
    height: ${vh(100)};
    width: 114px;
    // height: 100px;
    background: rgba(255,255,255,0);
    border: 1px solid #0B629A;
    padding: 3px;
    position: relative;
    cursor: pointer;

    .center-box {
        height: 100%;
        background: rgba(11,98,154,0);
        border: 1px solid #0B629A;
        padding: 2px;
    }

    .inner-box {
        height: 100%;
        background: linear-gradient(0deg, rgba(64,107,235,0.35), rgba(8,230,253,0.35));
        border: 1px solid #13B3FF;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0px 0px 6px rgba(255, 255, 255, .5);
        font-size: 18px;
        font-weight: 800;
        color: #FFFFFF;
        white-space: nowrap;
    }

    .small-block {
        width: 5px;
        height: 5px;
        background: #0B629A;
        position: absolute;
    }

    &.active {
        border-color: #13B3FF;

        .center-box {
            border-color: #13B3FF;
        }

        .inner-box {
            background: url(${flowBg}) no-repeat;
            background-size: 100% 100%;
            box-shadow: none;
        }

        .small-block {
            background: #13B3FF;
        }
    }  
`

const FlowBtn = ({
    text,
    event,
    active,
    style = {},
    onClick,
    visible
}) => {
    if (!visible) return ''
    return (
        <OuterBox
            style={style}
            onClick={onClick.bind(this, event)}
            className={active ? 'active' : ''}
        >
            <div className='small-block' style={{ left: 0, top: 0 }} />
            <div className='small-block' style={{ right: 0, top: 0 }} />
            <div className='small-block' style={{ left: 0, bottom: 0 }} />
            <div className='small-block' style={{ right: 0, bottom: 0 }} />
            <div className='center-box'>
                <div className='inner-box'>
                    {text}
                </div>
            </div>
        </OuterBox>
    )
}

export default FlowBtn
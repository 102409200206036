import { createActions } from 'redux-actions'
import api from '../../api'


const actions = createActions(
    {
        DD_GET_JUDGE_STATUS: (competitionId, successCB, failCB) => async (dispatch, getState) => {
            try {
                const { data } = await api.dd.ddGetJudgeStatus(competitionId)
                successCB && successCB(data)
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_JUDGE_APPLY_FROM_CODE: ({ competitionId, code }, successCB, failCB) => async (dispatch, getState) => {
            try {
                const res = await api.dd.ddApplyFromCode(competitionId, code)
                successCB && successCB(res.data)
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_UPDATE_USER_INFO: (payload, successCB, failCB) => async (dispatch, getState) => {
            try {
                await api.dd.ddUpdateUserInfo(payload)
                successCB && successCB()
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_JUDGE_APPLY_FROM_PHONE: (competitionId, successCB, failCB) => async (dispatch, getState) => {
            try {
                const res = await api.dd.ddApplyFromPhone(competitionId)
                successCB && successCB(res.data)
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_JUDGE_SIGN_IN: (competitionId, successCB, failCB) => async (dispatch, getState) => {
            try {
                await api.dd.ddJudgeSignIn(competitionId)
                successCB && successCB()
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_GET_JUDGEABLE_LIST: (successCB, failCB) => async (dispatch, getState) => {
            try {
                const res = await api.dd.ddGetJudgeableList()
                dispatch(actions.ddSetJudgeableList(res.data))
                successCB && successCB()
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_GET_AUTH_MOBILE: (payload, successCB, failCB) => async (dispatch, getState) => {
            try {
                const res = await api.dd.getAuthMobile(payload)
                successCB && successCB(res.data)
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_GET_JUDGE_INFO: (competitionId, successCB, failCB) => async (dispatch, getState) => {
            try {
                const res = await api.dd.ddGetJudgeInfo(competitionId)
                dispatch(actions.ddSetJudgeInfo(res.data))
                successCB && successCB()
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_JUDGE_SEND_SCORE: (data, successCB, failCB) => async (dispatch, getState) => {
            try {
                await api.dd.ddJudgeScore(data)
                successCB && successCB()
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_JUDGE_SEND_SCORE_ALL: (data, successCB, failCB) => async (dispatch, getState) => {
            try {
                await api.dd.ddJudgeScoreAll(data)
                successCB && successCB()
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
        DD_JUDGE_BACKOUT_SIGN_UP: (data, successCB, failCB) => async (dispatch, getState) => {
            try {
                await api.dd.backoutSignUp(data)
                successCB && successCB()
            } catch (err) {
                console.log(err)
                failCB && failCB(err)
            }
        },
    },
    'DD_SET_JUDGEABLE_LIST',
    'DD_SET_JUDGE_INFO',
    'DD_SET_IS_JUDGE'
)

export default { ...actions }
import React from 'react'
import styled, { css } from 'styled-components'
import { vw, vh } from 'common/utils'

const Wrapper = styled.div`
    width: ${vw(390)};
    height: ${vh(320)};
    border: 1px solid #014C93;
    cursor: pointer;

    &.active {
        border-color: #5BB8FB;
        box-shadow: 0px 0px 14px 0px #5bb8fb;
    }
`

const CommonRect = ({
    style = {},
    active,
    children
}) => {
    return (
        <Wrapper
            style={style}
            active={active}
            className={active ? 'active' : ''}
        >
            {children}
        </Wrapper>
    )
}

export default CommonRect
import React, { Component } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import QRCode from 'qrcode.react'

const Content = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Text = styled.div`
    font-size: 24px;
    font-weight: normal;
    color: #333333;
    margin-bottom: 16px;
`

const PayBtn = styled.div`
    height: 28px;
    line-height: 28px;
    background: #148BFF;
    border-radius: 4px;
    padding: 0 16px;
    color: white;
    cursor: pointer;
    display: block;
    margin-top: 32px;
`

class PayQRCodeModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { visible, data, openWeb, onCancel } = this.props

        return (
            <Modal
                width={472}
                title={'支付方式'}
                visible={visible}
                centered
                onCancel={onCancel}
                maskClosable={false}
                footer={null}
                bodyStyle={{ padding: 0 }}
            >
                <Content>
                    {data.qrcodeUrl && (
                        <>
                            <Text>金额：{data.amount}</Text>
                            <Text>云点：{data.point}</Text>
                            <Text>请打开支付宝扫码支付</Text>

                            <QRCode
                                value={data.qrcodeUrl}
                                size={200}
                            />
                            <PayBtn onClick={openWeb.bind(this, data.tradeNo)}>支付宝支付</PayBtn>
                        </>
                    )}
                </Content>
            </Modal>
        )
    }
}

export default PayQRCodeModal
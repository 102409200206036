import axios from 'axios'

const protocol = window.location.protocol
const host = window.location.hostname
const port = host === 'localhost' ? ':3000' : window.location.port
const baseURL = process.env.REACT_APP_API_BASE_URI || `${protocol}//${host}${port}`
const instance = axios.create({})

instance.defaults.timeout = 10 * 60 * 1000
// if (process.env.NODE_ENV !== 'development') {
//   instance.defaults.baseURL = baseURL
// }
instance.defaults.baseURL = baseURL

instance.interceptors.request.use(
  (config) => {
    // if (process.env.NODE_ENV === 'development') {
    //   config.url = config.url.replace('/api/', '/dev/')
    // }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
        case 400:
        case 403:
        case 404:
        case 500:
        case 503:
          console.log(err.response.status)
          break
        default:
          console.log(err)
          break
      }
    }
    return Promise.reject(err)
  }
)

export default instance
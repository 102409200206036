import React, { Component } from 'react'

export default class AliPay extends Component {
    componentDidMount() {
        const aliPayContent = localStorage.getItem('score-aliPayContent')
        const form = document.getElementById('aliPayForm')
        if (aliPayContent) {
            if (form) {
                form.remove()
            }
            let div = document.createElement('div')
            div.id = 'aliPayForm'
            div.innerHTML = aliPayContent
            document.body.appendChild(div)
            if (document.forms[0]) {
                document.forms[0].submit()
                localStorage.removeItem('score-aliPayContent')
            }
        }
    }

    render() {
        return (
            <></>
        )
    }
}
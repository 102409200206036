import { compose, createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk-fsa'
import promiseMiddleware from 'redux-promise'
import { createLogger } from 'redux-logger'
import { rootReducer } from './ducks/root'
import api from './api'

const opts = {
    extraArgument: {
        api
    }
}

let storeCreator
// noinspection JSUnresolvedVariable
if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
        collapsed: true,
        duration: true,
        stateTransformer: (state) => state.toJS()
    })

    storeCreator = applyMiddleware(thunkMiddleware.withOpts(opts), logger)

    // noinspection JSUnresolvedVariable
    if (window.devToolsExtension) {
        // noinspection JSUnresolvedFunction
        require('set.prototype.tojson')
        // noinspection JSUnresolvedFunction
        require('map.prototype.tojson')

        // noinspection JSUnresolvedFunction
        storeCreator = compose(storeCreator, window.devToolsExtension())
    }
} else {
    storeCreator = applyMiddleware(thunkMiddleware.withOpts(opts), promiseMiddleware)
}

export default createStore(rootReducer, storeCreator)

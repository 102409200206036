import React, { useState } from 'react'
import { message, Modal } from 'antd'
import CustomTable from 'components/common/CustomTable'
import styled from 'styled-components'

const GroupName = styled.div`
    font-size: 20px;
    color: #FFFFFF;
    white-space: nowrap;
`

const QiehuanIcon = styled.i`
    color: #70B0FF;
    margin-right: 16px;
    font-size: 18px;
    cursor: pointer;
`

const GroupPicker = ({
    initialValue = [],
    dataSource = [],
    onOk,
    readOnly
}) => {
    const defaultValue = initialValue ? [initialValue] : []
    const [selectedRows, setSelectedRows] = useState(defaultValue)
    const [visible, setVisible] = useState(false)
    const columns = [
        { title: '项目组名称', dataIndex: 'groupName', align: 'center' }
    ]

    const rowSelection = {
        type: 'radio',
        selectedRowKeys: selectedRows ? selectedRows.map(item => item.id) : [],
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
        }
    }

    const onCancel = () => {
        setVisible(false)
    }

    const onSubmit = () => {
        if (!selectedRows) {
            message.warning('请选择项目组')
            return
        }
        if (initialValue && initialValue.id === selectedRows[0].id) {
            onCancel()
        } else {
            onOk(selectedRows[0])
            onCancel()
        }
    }

    return (
        <>
            <GroupName>
                {!readOnly && (
                    <QiehuanIcon
                        className={'iconfont icon-qiehuan'}
                        onClick={() => { setVisible(true) }}
                    />
                )}
                {initialValue ? initialValue.groupName : ''}
            </GroupName>
            <Modal
                visible={visible}
                title={'项目组列表'}
                onCancel={onCancel}
                centered
                maskClosable={false}
                onOk={onSubmit}
            >
                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    bordered
                    size={'small'}
                    pagination={false}
                    rowKey={record => record.id}
                    scroll={{ y: 200 }}
                    rowSelection={rowSelection}
                />
            </Modal>
        </>
    )
}

export default GroupPicker
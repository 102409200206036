import { handleActions } from 'redux-actions'
import actions from './ddActions'
import { fromJS } from 'immutable'

export default handleActions(
    {
        [actions.ddSetJudgeableList](state, { payload }) {
            if (payload) {
                return state.set('judgeableList', fromJS(payload.asJudge))
            } else {
                return state.set('judgeableList', fromJS([]))
            }
        },
        [actions.ddSetJudgeInfo](state, { payload }) {
            if (payload) {
                return state.set('judgeInfo', fromJS(payload))
            } else {
                return state.set('judgeInfo', fromJS({}))
            }
        },
        [actions.ddSetIsJudge](state, { payload }) {
            return state.set('isJudge', payload)
        },
    },
    fromJS({
        judgeableList: fromJS([]), // 作为裁判的比赛列表
        judgeInfo: fromJS({}), 
        isJudge: null, 
    })
)
import { handleActions } from 'redux-actions'
import actions from './actions'
import { fromJS } from 'immutable'
import { getPageFromServer } from 'common/utils'
import { GUID } from 'common/utils'

export default handleActions(
  {
    [actions.settingSetMatchList](state, { payload }) {
      if (payload) {
        if (payload.content) {
          const data = payload.content
          const matchList = { data, pagination: getPageFromServer(payload) }
          return state.set('matchList', fromJS(matchList))
        } else {
          return state.set('matchList', fromJS({ data: [] }))
        }
      }
      return state
      // if (payload.groups) {
      //   return state.set('matchList', fromJS(payload.groups))
      // }
      // return state
    },
    [actions.settingSetMatchDetail](state, { payload }) {
      return state.set('matchDetail', fromJS(payload))
    },
    [actions.settingSetJudgeList](state, { payload }) {
      if (payload) {
        if (payload.content) {
          const data = payload.content.sort((a, b) => a.number > b.number ? 1 : -1)
          const judgeList = { data, pagination: getPageFromServer(payload) }
          return state.set('judgeList', fromJS(judgeList))
        } else {
          return state.set('judgeList', fromJS({ data: [] }))
        }
      }
      return state
    },
    [actions.settingSetJudgeGroupList](state, { payload }) {
      return state.set('judgeGroupList', fromJS(payload.judgeGroups))
    },
    [actions.settingSetManageableCompetitions](state, { payload }) {
      if (payload) {
        const data = payload.asAdmin || []
        return state.set('manageableCompetitions', fromJS(data))
      } else {
        return state.set('manageableCompetitions', fromJS([]))
      }
    },
    [actions.settingSetJudgeApprovedData](state, { payload }) {
      if (payload) {
        return state.set('judgeApprovedData', fromJS(payload.users))
      } else {
        return state.set('judgeApprovedData', fromJS([]))
      }
    },
    [actions.settingSetProjectList](state, { payload }) {
      if (payload) {
        if (payload.programGroups) {
          const data = payload.programGroups ? payload.programGroups.sort((a, b) => a.sort > b.sort ? 1 : -1) : []
          data.map(item => {
            if (item.programs) {
              item.programs = item.programs.sort((a, b) => a.sort > b.sort ? 1 : -1)
            }
            return item
          })
          return state.set('projectList', fromJS(data))
        } else {
          return state.set('projectList', fromJS([]))
        }
      }
      return state
    },
    [actions.settingSetProjectListBySort](state, { payload }) {
      if (payload) {
        if (payload.data) {
          return state.set('projectList', fromJS(payload.data))
        } else {
          return state.set('projectList', fromJS([]))
        }
      }
      return state
    },
    [actions.settingSetOtherProjectList](state, { payload }) {
      if (payload && payload.programGroups) {
        const data = []
        payload.programGroups.forEach(item => {
          data.push(...item.programs)
        })
        return state.set('otherProjectList', fromJS(data))
      } else {
        return state.set('otherProjectList', fromJS([]))
      }
    },
    [actions.settingSetStatisticsData](state, { payload }) {
      if (payload) {
        if (payload.programGroups) {
          const data = payload.programGroups.sort((a, b) => a.sort > b.sort ? 1 : -1)
          return state.set('statisticsData', fromJS(data))
        } else {
          return state.set('statisticsData', fromJS([]))
        }
      }
      return state
    },
    [actions.settingSetStatisticsDetail](state, { payload }) {
      if (payload) {
        if (payload.programItemResults) {
          const data = {}
          payload.programItemResults.forEach(({ number, name, groupName, itemName, score }) => {
            if (!data[number]) {
              data[number] = {
                number,
                name,
                groupName,
                itemNames: { [itemName]: score }
              }
            } else {
              data[number].itemNames = {
                ...data[number].itemNames,
                [itemName]: score
              }
            }
          })
          const programItemResults = Object.values(data)
          return state.set('statisticsDetail', fromJS(programItemResults))
        } else {
          return state.set('statisticsDetail', fromJS([]))
        }
      }
      return state
    },
    [actions.settingSetUserPoint](state, { payload }) {
      if (payload) {
        return state.set('userPoint', payload)
      }
      return state
    },
    [actions.settingSetPointRecordData](state, { payload = {} }) {
      const fields = { RECHARGE: 'pointRechargeData', USE: 'pointUseData' }
      const { type, data } = payload
      if (data) {
        const result = data.map(item => ({ ...item, id: GUID() }))
        return state.set(fields[type], fromJS(result))
      } else {
        return state.set(fields[type], fromJS([]))
      }
    },
    [actions.settingSetUserInfo](state, { payload }) {
      if (payload) {
        return state.set('userInfo', fromJS(payload))
      }
      return state
    },
    [actions.settingSetDingAddressBook](state, { payload }) {
      if (payload) {
        const result = {}
        const { parentId, deptId, data } = payload
        const dingAddressBook = state.get('dingAddressBook').toJS()
        if (deptId == 1) {
          result.deptName = '全部'
          result.childDepts = data.childDepts
          result.users = data.users.map(({ name, unionid }) => ({ label: name, value: unionid }))
          result.parentId = null
        } else {
          result.deptName = dingAddressBook[parentId].childDepts.find(item => item.deptId === deptId).deptName
          result.childDepts = data.childDepts
          result.users = data.users.map(({ name, unionid }) => ({ label: name, value: unionid }))
          result.parentId = parentId
          result.deptId = deptId
        }
        return state.setIn(['dingAddressBook', deptId], fromJS(result))
      } else {
        return state.set('dingAddressBook', fromJS({}))
      }
    },
    [actions.settingSetEnvironment](state, { payload }) {
      return state.set('environment', payload)
    },
    [actions.settingSetPointPlan](state, { payload }) {
      if (payload) {
        return state.set('pointPlan', fromJS(payload.plans))
      } else {
        return state.set('pointPlan', fromJS([]))
      }
    },
    [actions.settingSetSubUser](state, { payload }) {
      if (payload) {
        return state.set('subUserList', fromJS(payload.subUsers))
      } else {
        return state.set('subUserList', fromJS([]))
      }
    },
  },
  fromJS({
    matchList: fromJS({ data: [] }), // 比赛列表
    matchDetail: fromJS({}), // 比赛基础信息
    judgeList: fromJS({ data: [] }), // 评委列表
    judgeGroupList: fromJS([]), // 评委分组
    manageableCompetitions: fromJS([]), // 当前用户所创建的所有比赛
    judgeApprovedData: fromJS([]), // 已审核通过的评委
    projectList: fromJS([]), // 项目列表
    otherProjectList: fromJS([]), // 其他比赛的项目列表
    statisticsData: fromJS([]), // 统计列表
    statisticsDetail: fromJS([]), // 统计详情
    userPoint: 0, // 点数
    pointRechargeData: fromJS([]), // 充值记录
    pointUseData: fromJS([]), // 消费记录
    userInfo: fromJS({}), // 用户信息
    dingAddressBook: fromJS({}),
    environment: null, // 网页：web 电脑钉钉：dd-pc
    pointPlan: fromJS([]), // 支付宝套餐
    subUserList: fromJS([]), // 子账号列表
  })
)

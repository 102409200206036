import { createActions } from 'redux-actions'
import api from 'api'

const actions = createActions(
  {
    CONTROL_GET_CURRENT_MATCH: ({ competitionId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const result = await api.control.controlGetCurrentMatch(competitionId)
        dispatch(actions.controlSetCurrentMatch(result.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_PROJECT_GROUP_SWITCH: ({ competitionId, programGroupId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlProjectGroupSwitch(competitionId, programGroupId)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_PROJECT_ON_SPEECH: ({ competitionId, programId, itemId, test }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlProjectOnSpeech(competitionId, programId, itemId, test)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_PROJECT_SCORE_START: ({ competitionId, programId, itemId, test }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlScoreStart(competitionId, programId, itemId, test)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_PROJECT_SCORE_END: ({ competitionId, programId, itemId, test }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlScoreEnd(competitionId, programId, itemId, test)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_PROJECT_SHOW_RESULT: ({ competitionId, programId, itemId, test }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.control.controlProjectShowResult(competitionId, programId, itemId, test)
        successCB && successCB({ data: res.data, itemId, programId, timestamp: new Date().getTime() })
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_SIGN_IN: ({ competitionId, signInShowType }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlSignIn(competitionId, { signInShowType })
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_GET_SCREEN_RESULT: (successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.control.getScreenResult()
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_GET_TEST_LIST: ({ competitionId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlGetTestList(competitionId)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_GET_PROJECT_LIST: ({ competitionId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlGetProgramList(competitionId)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_CONTROL_SHOW_MATCH_PAGE: ({ competitionId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlShowMatchPage(competitionId)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_GET_CURRENT_RESULT: ({ groupId, competitionId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.control.getControlCurrentResult(groupId, competitionId)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_GET_FINAL_RESULT: ({ groupId, competitionId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.control.getControlFinalResult(groupId, competitionId)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_RESULT_PAGE_TO: ({ competitionId, itemId, pageNo, rankShowType }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlPageTo(competitionId, itemId, pageNo, rankShowType)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_MATCH_OFFICIAL: (id, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.competitionOfficial(id)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_GET_SIGN_IN_DATA: (competitionId, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.control.controlGetSignInJudges(competitionId)
        dispatch(actions.controlSetSignInData(res.data))
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_CHANGE_RANK_SHOW_TYPE: ({ competitionId, rankShowType }, successCB, failCB) => async (dispatch, getState) => {
      try {
        const res = await api.control.controlChangeRankShowType(competitionId, rankShowType)
        successCB && successCB(res.data)
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_BATTLE_START: ({ competitionId, programAId, programBId, test }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlBattleStart(competitionId, programAId, programBId, test)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_BATTLE_PAUSE: ({ competitionId, test }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlBattlePause(competitionId, test)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_BATTLE_RESUME: ({ competitionId, test }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlBattleResume(competitionId, test)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_BATTLE_FOUL: ({ competitionId, programId, foulId }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlBattleFoul(competitionId, programId, foulId)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_BATTLE_POINT: ({ competitionId, programId, point }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlBattlePoint(competitionId, programId, point)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
    CONTROL_BATTLE_DETERMINE: ({ competitionId, programAId, programBId, test }, successCB, failCB) => async (dispatch, getState) => {
      try {
        await api.control.controlBattleDetermine(competitionId, programAId, programBId, test)
        successCB && successCB()
      } catch (err) {
        console.log(err)
        failCB && failCB(err)
      }
    },
  },
  'CONTROL_SET_CURRENT_MATCH',
  'CONTROL_SET_SIGN_IN_DATA',
  'CONTROL_SET_CURRENT_MATCH_LINK_DATA'
)

export default { ...actions }
